<script lang="ts" setup>
import { defineProps, withDefaults, defineEmits } from 'vue'
import Slider from 'primevue/slider'
import InputNumber from 'primevue/inputnumber'

withDefaults(
  defineProps<{
    modelValue: number
    disabled?: boolean
  }>(),
  {
    disabled: false,
  },
)

const emit = defineEmits(['update:modelValue', 'sliderChanged', 'inputChanged'])

const updateValue = (value: number) => {
  emit('update:modelValue', value)
}
</script>

<template>
  <div class="slider-container">
    <Slider
      :min="0"
      :max="100"
      :modelValue="modelValue"
      @change="(e) => emit('sliderChanged', e)"
      :disabled="disabled"
    />
    <InputNumber
      fluid
      style="flex: 0.8"
      :min="0"
      :max="100"
      :modelValue="modelValue"
      @input="updateValue($event.value)"
      suffix="%"
      :disabled="disabled"
    />
  </div>
</template>

<style scoped>
.slider-container {
  display: flex;
  width: 100%;
  gap: 20px;
}
.slider-container ::v-deep .p-slider {
  flex: 2;
}
.slider-container ::v-deep .p-slider::before {
  content: '0%';
  left: 0;
  font-size: 12px;
  position: absolute;
  top: 14px;
}
.slider-container ::v-deep .p-slider::after {
  content: '100%';
  right: 0;
  font-size: 12px;
  position: absolute;
  top: 14px;
}
</style>
