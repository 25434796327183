import { getDeviceFeatureId } from '@/modules/devices'
import { LayerControlSpace } from '@/pages/LayerSensorControl/store'
import store from '@/pages/LayerSensorControl/store'

export const showDeviceSelected = (device, { selectedFeature }): boolean => {
  if (!selectedFeature) return false
  return selectedFeature.feature.id === getDeviceFeatureId(device)
}

export const getSensorTargetsForSpace = (space: LayerControlSpace) => {
  return (
    store.activeFloorplan.sensors
      // Filter sensors with targets within the given space
      .filter((sensor) => {
        const isGroupTarget =
          sensor.target &&
          sensor.target.type === 'group' &&
          space.groups.some((group) => group.pid === sensor.target.pid)

        const isDeviceTarget =
          sensor.target &&
          sensor.target.type === 'device' &&
          space.devices.some((device) => device.pid === sensor.target.pid)

        return isGroupTarget || isDeviceTarget
      })
      // Return a list of targets including all matching sensors
      .reduce((acc, sensor) => {
        const existingTarget = acc.find((item) => item.target.pid === sensor.target.pid)

        if (existingTarget) {
          // Target already exists, add this sensor to ti
          existingTarget.sensors.push(sensor)
        } else {
          // Create the target with the sensor
          acc.push({
            target: sensor.target,
            sensors: [sensor],
          })
        }

        return acc
      }, [])
  )
}

export const getSpaceTargetsConfiguration = (space: LayerControlSpace) => {
  const configurations = getSensorTargetsForSpace(space).map((sensorTarget) => sensorTarget.sensors[0].configurations)

  const uniqueConfigurations = new Set(
    configurations.map(
      ({ motion_level, dim_level, time_delay_1, time_delay_2 }) =>
        `${motion_level}-${dim_level}-${time_delay_1}-${time_delay_2}`,
    ),
  )

  return uniqueConfigurations.size === 1
    ? configurations[0]
    : { motion_level: 0, dim_level: 0, time_delay_1: 0, time_delay_2: 0 }
}
