<script setup lang="ts">
import { AvionLocationSpace } from '@/entities/AvionLocation'
import { defineProps, PropType, onMounted, ref, watch } from 'vue'
import { showSpaceSelected } from './helpers'
import Accordion from 'primevue/accordion'
import AccordionPanel from 'primevue/accordionpanel'
import AccordionHeader from 'primevue/accordionheader'
import AccordionContent from 'primevue/accordioncontent'

import GroupList from '@/pages/LayerDeviceControl/components/GroupList/index.vue'
import { createLogger } from '@/utils/logger'
import { selectSpace, selectGroup, deselectAll } from '@/pages/LayerDeviceControl/editor'
import store from '@/pages/LayerDeviceControl/store'

const logger = createLogger('SpaceCard')

defineOptions({
  name: 'SpaceCard',
})

const props = defineProps({
  space: {
    type: Object as PropType<AvionLocationSpace>,
    required: true,
  },
})

const isSelected = ref(showSpaceSelected(props.space))

const isInSpace = (features, newVal, type) => {
  return features.some((feature) => feature.pid === newVal.feature.properties[type])
}

watch(
  () => store.selectedFeature,
  (newSelectedFeature) => {
    if (!newSelectedFeature) {
      isSelected.value = false
      return
    }

    switch (newSelectedFeature.feature.properties.type) {
      case 'group':
        const groups = store.getLinkedGroups(props.space)

        isSelected.value = isInSpace(groups, newSelectedFeature, 'groupId')
        break
      case 'device':
        const devices = store.getLinkedDevices(props.space)
        isSelected.value = isInSpace(devices, newSelectedFeature, 'deviceId')
        break
      default:
        isSelected.value = props.space.pid === newSelectedFeature.feature.properties.spaceId
    }
  },
)

const handleSelectedSpace = () => {
  selectSpace(props.space.pid)
}

const handleSelectedGroup = (group) => {
  selectGroup(group.pid)
}

onMounted(() => {
  logger.debug(`${props.space.name}`, props.space.groups)
})

const updateCollapsed = () => {
  if (isSelected.value) {
    deselectAll()
  } else {
    selectSpace(props.space.pid)
  }
}
</script>

<template>
  <Accordion class="space-card" :value="isSelected ? '1' : '0'" @update:value="updateCollapsed">
    <AccordionPanel value="1">
      <AccordionHeader>
        <h3><i class="pi pi-box" style="margin-right: 4px" /> {{ props.space.name }}</h3>
      </AccordionHeader>
      <AccordionContent>
        <article>
          <GroupList
            :groups="store.getLinkedGroups(props.space)"
            defaultLabel="All Groups"
            :spaceId="props.space.pid"
            :isDefaultActive="showSpaceSelected(props.space)"
            @handleDefaultSelected="handleSelectedSpace"
            @handleItemSelected="handleSelectedGroup"
          />
        </article>
      </AccordionContent>
    </AccordionPanel>
  </Accordion>
</template>

<style scoped>
.space-card {
  box-shadow: var(--card-shadow);
}

.space-card ::v-deep .p-accordioncontent-content {
  padding: 0;
  border: 1px solid #2d4263;
  border-top: none;
  border-radius: 0px 0px 6px 6px;
}

.space-card ::v-deep .p-accordionheader {
  --p-accordion-header-active-background: #2d4263;
  --p-accordion-header-active-color: #fff;
  --p-accordion-header-toggle-icon-active-color: #1393b0;
  --p-accordion-header-toggle-icon-active-hover-color: #1393b0;
  --p-accordion-header-active-hover-background: #2d4263;
  --p-accordion-header-active-hover-color: #fff;
  border-radius: 6px 6px 0px 0px;
}

header {
  margin-bottom: 8px;
}

h3 {
  margin: 0;
  font-size: 14px;
}
</style>
