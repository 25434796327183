<script setup lang="ts">
import Dialog from 'primevue/dialog'
import FileUpload from '@/components/FileUpload.vue'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import { ref, defineProps, PropType, defineEmits } from 'vue'
import { useRoute } from 'vue-router'
import { useToast } from 'primevue/usetoast'
import http from '@/modules/http.ts'

defineProps({
  visible: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const emit = defineEmits(['close', 'created'])
const isCreating = ref(false)
const name = ref(null)
const fileInput = ref(null)
const toast = useToast()

const route = useRoute()

const handleSave = async () => {
  isCreating.value = true
  const locationId = route.params.locationId
  const result: any = await http.post(`/locations/${locationId}/floorplans`, createFormData(), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  if (result.error) {
    isCreating.value = false
    return toast.add({
      summary: 'Error while creating floorplan',
      detail: `${result.error} ${result.message}`,
      life: 1300,
      severity: 'error',
    })
  }

  isCreating.value = false
  emit('created', result)
  handleClose()
}

const createFormData = () => {
  const formData = new FormData()
  formData.append('name', name.value)
  formData.append('fileHeight', fileInput.value.height)
  formData.append('fileWidth', fileInput.value.width)
  formData.append('file', fileInput.value.file)
  return formData
}

const handleClose = () => {
  name.value = null
  emit('close')
}
</script>

<template>
  <Dialog
    :style="{ width: '40vw' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    :visible="visible"
    v-on:update:visible="handleClose"
    modal
    header="Add a new floor plan"
  >
    <div class="flex flex-column gap-2 mb-2">
      <label for="name">Floor plan name</label>
      <InputText v-model="name" id="name" :invalid="!name" autofocus />
    </div>
    <div class="flex flex-column gap-2 mb-2">
      <label for="name">Floor plan image</label>
      <FileUpload v-model="fileInput" />
    </div>
    <template #footer>
      <Button label="Cancel" text severity="secondary" @click="handleClose" />
      <Button
        :loading="isCreating"
        :disabled="!name || !fileInput || isCreating"
        label="Add Floorplan"
        @click="handleSave"
      />
    </template>
  </Dialog>
</template>

<style scoped></style>
