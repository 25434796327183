import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import store from './store'
import './editor.css'
import { createFloorplanMap, groupStyles, spaceStyles, deviceIcons } from '@/modules/leaflet'

import { createLogger } from '@/utils/logger'
const logger = createLogger('device-control-layer:editor')

let map = null
// Create separate layer groups for each type
let deviceLayer = L.layerGroup()
let spaceLayer = L.layerGroup()
let groupLayer = L.layerGroup()
// Track individual layers for selection management
let layerItems = []

export const removeMap = () => {
  deviceLayer.clearLayers()
  spaceLayer.clearLayers()
  groupLayer.clearLayers()
  map?.remove()
}

export const deselectAll = () => {
  layerItems.forEach((layer) => {
    layer.onDeselect()
  })
  store.setSelectedFeature(null)
}

export const selectDevice = (devicePid) => {
  selectFeature(devicePid, 'device', 'deviceId')
}

export const selectSpace = (spacePid) => {
  selectFeature(spacePid, 'space', 'spaceId')
}
export const selectGroup = (groupPid) => {
  selectFeature(groupPid, 'group', 'groupId')
}

const selectFeature = (pid, type, property) => {
  const feature = store.features.find((feature) => feature.properties[property] === pid)
  if (!feature) return

  store.setSelectedFeature(feature, type)

  const layer = layerItems.find((layer) => layer.feature === feature)
  if (!layer) {
    logger.warn('Feature not found in layers:', feature)
    return
  }

  layerItems.forEach((layer) => {
    layer.onDeselect()
  })

  switch (type) {
    case 'device':
      layer.setIcon(deviceIcons.selected)
      break
    case 'group':
      layer.setStyle?.(groupStyles.selected)
      break
    case 'space':
      layer.setStyle?.(spaceStyles.selected)
      break
  }

  const mapBounds = map.getBounds()
  const layerBounds = layer.getBounds?.() || layer.getLatLng?.()
  if (layerBounds && !mapBounds.contains(layerBounds)) {
    const targetCenter = layer.getBounds ? layerBounds.getCenter() : layerBounds
    map.flyTo(targetCenter, map.getZoom(), {
      pan: {
        animate: true,
        duration: 0.4,
      },
      zoom: {
        animate: true,
        duration: 0.4,
      },
    })
  }
}

const onDeviceFeature = (feature, layer) => {
  logger.debug('Setting up device feature:', feature)
  layer.on('click', (param) => {
    L.DomEvent.stopPropagation(param)

    logger.debug('Device layer clicked:', param)

    // disable edit for all
    deselectAll()

    layer.setIcon(deviceIcons.selected)
    store.setSelectedFeature(feature, 'device')
  })

  layer.onDeselect = () => {
    logger.debug('Deselecting device layer:', layer)
    layer.setIcon(deviceIcons.unselected)
  }
}

export function redrawMap(features) {
  logger.debug('Redrawing map...')
  // Clear all layers
  deviceLayer.clearLayers()
  spaceLayer.clearLayers()
  groupLayer.clearLayers()
  layerItems = []

  // Add new features to the map
  features.forEach((feature) => {
    const geoJSONLayer = L.geoJSON([feature], {
      pane: `${feature.properties.type}Pane`,
      onEachFeature: (feature, layer) => {
        logger.debug('Processing feature:', feature)

        switch (feature.properties.type) {
          case 'device':
            onDeviceFeature(feature, layer)
            break
          case 'space':
            onSpaceFeature(feature, layer)
            break
          case 'group':
            onGroupFeature(feature, layer)
            break
        }

        logger.debug('Adding layer to global layers array:', layer)
        layerItems.push(layer)
      },
      pointToLayer: function (feature, coordinates) {
        const isSelected = store.selectedFeature?.feature.id === feature.id
        const icon = isSelected ? deviceIcons.selected : deviceIcons.unselected

        const deviceMarker = L.marker(coordinates, { icon }).bindTooltip(feature.properties.label, { sticky: true })

        deviceMarker.on('mouseover', function (ev) {
          ev.target.openTooltip()
        })

        return deviceMarker
      },
    })

    // Add to appropriate layer group based on feature type
    switch (feature.properties.type) {
      case 'device':
        geoJSONLayer.addTo(deviceLayer)
        break
      case 'space':
        geoJSONLayer.addTo(spaceLayer)
        break
      case 'group':
        geoJSONLayer.addTo(groupLayer)
        break
    }
  })
}

const onSpaceFeature = (feature, layer) => {
  logger.debug('Setting up space feature:', feature)
  layer.on('click', (param) => {
    L.DomEvent.stopPropagation(param)

    logger.debug('Space layer clicked:', param)

    // disable edit for all
    deselectAll()

    layer.setStyle?.(spaceStyles.selected)
    store.setSelectedFeature(feature, 'space')
  })

  layer.onDeselect = () => {
    logger.debug('Deselecting space layer:', layer)
    layer.setStyle?.(spaceStyles.normal)
  }

  layer.setStyle?.(spaceStyles.normal)

  layer.bindTooltip(`Space: ${feature.properties.label}`, {
    sticky: true,
  })

  layer.on('mouseover', function () {
    this.openTooltip()
  })

  layer.on('mouseout', function () {
    this.closeTooltip()
  })
}

const onGroupFeature = (feature, layer) => {
  logger.debug('Setting up group feature:', feature)

  layer.on('click', (param) => {
    L.DomEvent.stopPropagation(param)

    logger.debug('Group layer clicked:', param)

    // disable edit for all
    deselectAll()

    layer.setStyle?.(groupStyles.selected)
    store.setSelectedFeature(feature, 'group')
  })

  layer.onDeselect = () => {
    logger.debug('Deselecting group layer:', layer)
    layer.setStyle?.(groupStyles.normal)
  }

  layer.setStyle?.(groupStyles.normal)

  layer.bindTooltip(`${feature.properties.label}`, {
    permanent: true,
    direction: 'center',
  })
}

export function createMap(leafletElement: any) {
  map = createFloorplanMap(leafletElement, store.activeFloorplan)

  map.on('click', () => {
    deselectAll()
  })

  // Add all layer groups to the map
  spaceLayer.addTo(map)
  groupLayer.addTo(map)

  // Create layer control
  const overlays = {
    Devices: deviceLayer,
    Groups: groupLayer,
    Spaces: spaceLayer,
  }

  L.control
    .layers(null, overlays, {
      collapsed: true,
    })
    .addTo(map)

  // Groups in a higher zIndex to avoid being covered by a Space
  map.createPane('spacePane').style.zIndex = '400'
  map.createPane('groupPane').style.zIndex = '401'
}
