import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import store from './store'
import deviceIcon from '@/assets/device.svg?raw'
import './editor.css'
import { createFloorplanMap, groupStyles, spaceStyles } from '@/modules/leaflet'

import { createLogger } from '@/utils/logger'
const logger = createLogger('device-control-layer:editor')

let map = null
// Create separate layer groups for each type
let deviceLayer = L.layerGroup()
let spaceLayer = L.layerGroup()
let groupLayer = L.layerGroup()
// Track individual layers for selection management
let layerItems = []

const icons = {
  selectedOn: L.divIcon({
    className: 'device-selected-icon-on',
    iconSize: [34, 34],
    html: deviceIcon,
  }),
  selectedOff: L.divIcon({
    className: 'device-selected-icon-off',
    iconSize: [34, 34],
    html: deviceIcon,
  }),
  selectedUnknown: L.divIcon({
    className: 'device-selected-icon-unknown',
    iconSize: [34, 34],
    html: deviceIcon,
  }),
  turnedOn: L.divIcon({
    className: 'device-icon-on',
    iconSize: [30, 30],
    html: deviceIcon,
  }),
  turnedOff: L.divIcon({
    className: 'device-icon-off',
    iconSize: [30, 30],
    html: deviceIcon,
  }),
  unknown: L.divIcon({
    className: 'device-icon-unknown',
    iconSize: [30, 30],
    html: deviceIcon,
  }),
}

export const removeMap = () => {
  if (map) {
    deviceLayer.clearLayers()
    spaceLayer.clearLayers()
    groupLayer.clearLayers()
    map.remove()
  }
}

const getIconForFeature = (feature) => {
  const { turnedOn } = feature.properties
  if (turnedOn === undefined) {
    return icons.unknown
  }
  return turnedOn ? icons.turnedOn : icons.turnedOff
}

const getSelectedIconForFeature = (feature) => {
  const { turnedOn } = feature.properties
  if (turnedOn === undefined) {
    return icons.selectedUnknown
  }
  return turnedOn ? icons.selectedOn : icons.selectedOff
}

export const deselectAll = () => {
  layerItems.forEach((layer) => {
    layer.onDeselect()
  })
  store.setSelectedFeature(null)
}

export const selectDevice = (devicePid) => {
  selectFeature(devicePid, 'device', 'deviceId')
}

export const selectSpace = (spacePid) => {
  selectFeature(spacePid, 'space', 'spaceId')
}

export const selectGroup = (groupPid) => {
  selectFeature(groupPid, 'group', 'groupId')
}

const selectFeature = (pid, type, property) => {
  const feature = store.features.find((feature) => feature.properties[property] === pid)
  if (!feature) return

  store.setSelectedFeature(feature, type)

  const layer = layerItems.find((layer) => layer.feature === feature)
  if (!layer) {
    logger.warn('Feature not found in layers:', feature)
    return
  }

  layerItems.forEach((layer) => {
    layer.onDeselect()
  })

  switch (type) {
    case 'device':
      layer.setIcon(getSelectedIconForFeature(feature))
      break
    case 'group':
      layer.setStyle?.(groupStyles.selected)
      break
    case 'space':
      layer.setStyle?.(spaceStyles.selected)
      break
  }

  const mapBounds = map.getBounds()
  const layerBounds = layer.getBounds?.() || layer.getLatLng?.()
  if (layerBounds && !mapBounds.contains(layerBounds)) {
    const targetCenter = layer.getBounds ? layerBounds.getCenter() : layerBounds
    map.flyTo(targetCenter, map.getZoom(), {
      pan: {
        animate: true,
        duration: 0.4,
      },
      zoom: {
        animate: true,
        duration: 0.4,
      },
    })
  }
}

const onDeviceFeature = (feature, layer) => {
  logger.debug('Setting up device feature:', feature)
  layer.on('click', (param) => {
    L.DomEvent.stopPropagation(param)
    logger.debug('Device layer clicked:', param)
    deselectAll()
    layer.setIcon(getSelectedIconForFeature(feature))
    store.setSelectedFeature(feature, 'device')
  })

  layer.onDeselect = () => {
    logger.debug('Deselecting device layer:', layer)
    layer.setIcon(getIconForFeature(feature))
  }
}

export function redrawMap(features) {
  logger.debug('Redrawing map...')
  // Clear all layers
  deviceLayer.clearLayers()
  spaceLayer.clearLayers()
  groupLayer.clearLayers()
  layerItems = []

  // Add new features to appropriate layers
  features.forEach((feature) => {
    const geoJSONLayer = L.geoJSON([feature], {
      pane: `${feature.properties.type}Pane`,
      onEachFeature: (feature, layer) => {
        logger.debug('Processing feature:', feature)

        switch (feature.properties.type) {
          case 'device':
            onDeviceFeature(feature, layer)
            break
          case 'space':
            onSpaceFeature(feature, layer)
            break
          case 'group':
            onGroupFeature(feature, layer)
            break
        }

        logger.debug('Adding layer to layerItems array:', layer)
        layerItems.push(layer)
      },
      pointToLayer: function (feature, coordinates) {
        const isSelected = store.selectedFeature?.feature.id === feature.id
        const icon = isSelected ? getSelectedIconForFeature(feature) : getIconForFeature(feature)

        const deviceMarker = L.marker(coordinates, { icon }).bindTooltip(feature.properties.label, { sticky: true })

        deviceMarker.on('mouseover', function (ev) {
          ev.target.openTooltip()
        })

        return deviceMarker
      },
    })

    // Add to appropriate layer group based on feature type
    switch (feature.properties.type) {
      case 'device':
        geoJSONLayer.addTo(deviceLayer)
        break
      case 'space':
        geoJSONLayer.addTo(spaceLayer)
        break
      case 'group':
        geoJSONLayer.addTo(groupLayer)
        break
    }
  })
}

const onSpaceFeature = (feature, layer) => {
  logger.debug('Setting up space feature:', feature)
  layer.on('click', (param) => {
    L.DomEvent.stopPropagation(param)
    logger.debug('Space layer clicked:', param)
    deselectAll()
    layer.setStyle?.(spaceStyles.selected)
    store.setSelectedFeature(feature, 'space')
  })

  layer.onDeselect = () => {
    logger.debug('Deselecting space layer:', layer)
    layer.setStyle?.(spaceStyles.normal)
  }

  layer.setStyle?.(spaceStyles.normal)

  layer.bindTooltip(`Space: ${feature.properties.label}`, {
    sticky: true,
  })

  layer.on('mouseover', function () {
    this.openTooltip()
  })

  layer.on('mouseout', function () {
    this.closeTooltip()
  })
}

const onGroupFeature = (feature, layer) => {
  logger.debug('Setting up group feature:', feature)

  layer.on('click', (param) => {
    L.DomEvent.stopPropagation(param)
    logger.debug('Group layer clicked:', param)
    deselectAll()

    if (feature.properties.turnedOn === 1) {
      layer.setStyle?.(groupStyles.turnedOnSelected)
    } else if (feature.properties.turnedOn === 0) {
      layer.setStyle?.(groupStyles.turnedOffSelected)
    } else {
      layer.setStyle?.(groupStyles.normal)
    }
    store.setSelectedFeature(feature, 'group')
  })

  layer.onDeselect = () => {
    logger.debug('Deselecting group layer:', layer)
    if (feature.properties.turnedOn === 1) {
      layer.setStyle?.(groupStyles.turnedOn)
    } else if (feature.properties.turnedOn === 0) {
      layer.setStyle?.(groupStyles.turnedOff)
    } else {
      layer.setStyle?.(groupStyles.normal)
    }
  }

  if (feature.properties.turnedOn === 1) {
    layer.setStyle?.(groupStyles.turnedOn)
  } else if (feature.properties.turnedOn === 0) {
    layer.setStyle?.(groupStyles.turnedOff)
  } else {
    layer.setStyle?.(groupStyles.normal)
  }

  layer.bindTooltip(`${feature.properties.label}`, {
    permanent: true,
    direction: 'center',
  })
}

export function createMap(leafletElement: any) {
  map = createFloorplanMap(leafletElement, store.activeFloorplan)

  map.on('click', () => {
    deselectAll()
  })

  // Add all layer groups to the map
  deviceLayer.addTo(map)
  spaceLayer.addTo(map)
  groupLayer.addTo(map)

  // Create layer control
  const overlays = {
    Devices: deviceLayer,
    Groups: groupLayer,
    Spaces: spaceLayer,
  }

  L.control
    .layers(null, overlays, {
      collapsed: true,
    })
    .addTo(map)

  // Groups in a higher zIndex to avoid being covered by a Space
  map.createPane('spacePane').style.zIndex = '400'
  map.createPane('groupPane').style.zIndex = '401'

  return map
}
