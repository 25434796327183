<script lang="ts" setup>
import { ref, defineProps, defineEmits, watch } from 'vue'
import DimLevel from '@/components/Control/DimLevel.vue'
import Button from 'primevue/button'
import { toPercentage } from '@/modules/sensors'

defineOptions({
  name: 'GroupControl',
})
const statusUnknown = ref(false)
const props = defineProps<{
  group: { dimLevel: number; turnedOn: boolean }
  disabled?: boolean
  isLoading?: boolean
}>()

const groupDimValue = ref(0)
const groupOnOffValue = ref(false)

const emit = defineEmits(['onGroupStateChanged'])

watch(
  () => props.group,
  (group) => {
    groupDimValue.value = toPercentage(group?.dimLevel)
    groupOnOffValue.value = Boolean(group?.turnedOn) ?? false
  },
  { immediate: true },
)

const handleGroupStateChanged = () => {
  emit('onGroupStateChanged', { onOff: groupOnOffValue.value, dim: groupDimValue.value })
}
</script>

<template>
  <p>Dim Level:</p>
  <div class="slider-container">
    <DimLevel
      v-model.number="groupDimValue"
      @slider-changed="(value) => (groupDimValue = value)"
      @input="groupDimValue = $event.value"
      :disabled="statusUnknown || disabled || isLoading"
    />
  </div>

  <Button
    label="Apply configuration"
    :loading="isLoading"
    @click="handleGroupStateChanged"
    :disabled="statusUnknown || disabled"
  />
</template>

<style scoped></style>
