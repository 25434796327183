<script setup lang="ts">
import { PropType, defineProps, defineEmits, ref } from 'vue'
import { Group } from '@/entities/AvionLocation'
import store from '@/pages/LayerDeviceControl/store'

const props = defineProps({
  groups: {
    type: Array as PropType<Group[]>,
    required: true,
  },
  defaultLabel: {
    type: String,
    required: true,
  },
  isDefaultActive: {
    type: Boolean,
    required: true,
  },
  spaceId: String,
})

let selectedItem = ref(null)

const emit = defineEmits(['handleDefaultSelected', 'handleItemSelected'])

const isDefaultActive = () => {
  return null === store.getSelectedGroup()
}

const isItemActive = (group: Group) => {
  return group.name === store.getSelectedGroup()?.name
}

const handleDefaultClick = () => {
  selectedItem.value = props.defaultLabel
  emit('handleDefaultSelected')
}

const handleGroupClick = (group: Group) => {
  selectedItem.value = group.name
  emit('handleItemSelected', group)
}
</script>

<template>
  <li :class="{ active: isDefaultActive() }" :key="spaceId" @click.stop="handleDefaultClick">
    <label>{{ defaultLabel }}</label>
  </li>

  <li
    v-for="group in groups"
    :key="group.pid"
    :class="{ active: isItemActive(group) }"
    @click.stop="handleGroupClick(group)"
  >
    <label><i class="pi pi-th-large" style="margin-right: 4px" />{{ group.name }}</label>
  </li>
</template>

<style scoped>
li {
  width: 100%;
  list-style: none;
  transition: background-color 0.3s;
  padding: 8px 16px;
}

li,
label {
  cursor: pointer;
  width: 100%;
}

li:hover,
li.active {
  background: #eff4ff;
}
li:last-child {
  border-radius: 0px 0px 6px 6px;
}
</style>
