import L, { LatLngBoundsExpression, Map, Marker } from 'leaflet'
import deviceIcon from '@/assets/device.svg?raw'
import { Floorplan } from '@/entities/Floorplan'

export const spaceStyles = {
  normal: {
    color: '#616161',
    fillColor: '#424242',
    weight: 2,
    fillOpacity: 0.2,
  },
  selected: {
    color: '#1D4ED8',
    fillColor: '#8183F4',
    weight: 2,
    fillOpacity: 0.5,
  },
}

export const groupStyles = {
  normal: {
    color: '#616161',
    fillColor: '#424242',
    dashArray: '4, 5',
    weight: 2,
    fillOpacity: 0.2,
  },
  selected: {
    color: '#1D4ED8',
    fillColor: '#8183F4',
    dashArray: '4, 5',
    weight: 2,
    fillOpacity: 0.5,
  },
  turnedOn: {
    color: '#616161',
    fillColor: '#4ab54a',
    dashArray: '4, 5',
    weight: 2,
    fillOpacity: 0.5,
  },
  turnedOff: {
    color: '#616161',
    fillColor: '#e47878',
    dashArray: '4, 5',
    weight: 2,
    fillOpacity: 0.5,
  },
  turnedOnSelected: {
    color: '#1D4ED8',
    fillColor: '#4ab54a',
    dashArray: '4, 5',
    weight: 2,
    fillOpacity: 0.5,
  },
  turnedOffSelected: {
    color: '#1D4ED8',
    fillColor: '#e47878',
    dashArray: '4, 5',
    weight: 2,
    fillOpacity: 0.5,
  },
}

export const deviceIcons = {
  unselected: L.divIcon({
    className: 'device-icon-unselected',
    iconSize: [30, 30],
    html: deviceIcon,
  }),
  selected: L.divIcon({
    className: 'device-icon-selected',
    iconSize: [30, 30],
    html: deviceIcon,
  }),
}

export const getDeviceMarker = (feature, coordinates): Marker => {
  return L.marker(coordinates, { icon: deviceIcons.unselected })
    .bindTooltip(feature.properties.label)
    .on('mouseover', (e) => {
      e.target.openTooltip()
    })
}

export function getImageBoundaries(imageWidth: number, imageHeight: number) {
  const baseSize = 2000
  const floorplanWidth = imageWidth ?? baseSize
  const floorplanHeight = imageHeight ?? baseSize
  const scaleFactor = baseSize / Math.max(floorplanWidth, floorplanHeight)

  const scaledWidth = floorplanWidth * scaleFactor
  const scaledHeight = floorplanHeight * scaleFactor

  return { scaledWidth, scaledHeight }
}

export function createFloorplanMap(leafletElement: any, floorplan: Floorplan): Map {
  if (!floorplan) return
  const { image_url: imageUrl, image_width: width, image_height: height } = floorplan

  const imageOverlay = decodeURIComponent(imageUrl)

  const map = L.map(leafletElement.value, {
    crs: L.CRS.Simple,
    minZoom: -1,
    zoomSnap: 0.5,
    zoomDelta: 0.5,
  })

  map.zoomControl.setPosition('topright')

  const { scaledWidth, scaledHeight } = getImageBoundaries(width, height)

  const bounds = [
    [0, 0],
    [scaledHeight, scaledWidth],
  ]

  const image = L.imageOverlay(imageOverlay, bounds as LatLngBoundsExpression).setOpacity(0.7)
  image.addTo(map)

  map.fitBounds(bounds as LatLngBoundsExpression)
  map.setZoom(0)

  return map
}
