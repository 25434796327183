<script lang="ts" setup>
import { ref, defineProps, onMounted, watch, defineEmits } from 'vue'
import Panel from '@/components/Panel.vue'
import ToggleSwitch from 'primevue/toggleswitch'
import { LayerControlDevice } from '../../store'
import { useDebounceFn } from '@vueuse/core'
import DimLevel from '@/components/Control/DimLevel.vue'

defineOptions({
  name: 'DeviceDetailsCard',
})

const dimValue = ref(0)
const onOffValue = ref(false)
const statusUnknown = ref(false)
const emit = defineEmits(['dimChanged', 'onOffChanged'])

const props = defineProps<{
  device: LayerControlDevice
  collapsed: Boolean
}>()

watch(
  () => props.device,
  (newVal) => {
    if (newVal) {
      initialize()
    }
  },
)

onMounted(() => {
  initialize()
})

const initialize = () => {
  if (props.device.statuses) {
    dimValue.value = Math.ceil((getStatusValue('dim') * 100) / 255)
    onOffValue.value = Boolean(getStatusValue('on_off'))
    statusUnknown.value = false
  } else {
    dimValue.value = 0
    statusUnknown.value = true
  }
}

const getStatusValue = (name: string) => {
  return JSON.parse(props.device.statuses.find((s) => s.name === name).value)[0]
}

const debouncedHandleDimChange = useDebounceFn((device) => {
  handleDimChange(device)
}, 500)

const handleInputDimChange = (device: LayerControlDevice, value: any) => {
  if (value !== null && value !== undefined) {
    dimValue.value = value
    debouncedHandleDimChange(device)
  }
}

const handleDimChange = (device: LayerControlDevice) => {
  const value = (dimValue.value * 255) / 100

  emit('dimChanged', {
    name: 'dim',
    value,
    devicePid: device.pid,
    deviceName: device.name,
  })
}

const handleOnOffChange = (device: LayerControlDevice, newValue: boolean) => {
  onOffValue.value = newValue

  emit('onOffChanged', {
    name: 'on_off',
    value: newValue ? 1 : 0,
    devicePid: device.pid,
    deviceName: device.name,
  })
}
</script>

<template>
  <Panel :collapsed="collapsed">
    <template #togglericon="{ collapsed }">
      <span class="pi pi-chevron-up" v-if="collapsed"></span>
      <span class="pi pi-chevron-down" v-else></span>
    </template>
    <div>
      <p>Dim Level:</p>
      <div class="slider-container">
        <DimLevel
          v-model.number="dimValue"
          @sliderChanged="
            (value) => {
              dimValue = value
              debouncedHandleDimChange(device)
            }
          "
          @input="handleInputDimChange(device, $event.value)"
          :disabled="statusUnknown"
        />
      </div>
      <div class="on-off-container">
        <p>On/Off:</p>
        <ToggleSwitch
          :modelValue="onOffValue"
          @change="(e) => handleOnOffChange(device, e.target.checked)"
          :disabled="statusUnknown"
        />
      </div>
    </div>
  </Panel>
</template>

<style scoped>
.on-off-container {
  padding-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.on-off-container p {
  margin: 0;
}
</style>
