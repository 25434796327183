<script setup lang="ts">
import { defineProps, defineEmits, ref, watch } from 'vue'
import store from '@/pages/FloorplanManager/store'
import Tree from 'primevue/tree'
import { Device } from '@/entities/AvionLocation'

const props = defineProps({
  spaceId: String,
  group: Object,
})

const emit = defineEmits(['selectGroup', 'deselectGroup', 'selectDevice', 'deselectDevice'])

const expandedKeys = ref<{ [key: string]: boolean }>({})

watch(
  () => store.selectedFeature?.feature,
  (newValue) => {
    if (newValue) {
      const newValueId = newValue.id.split(':')[1]
      const isContained = props.group.devices.includes(newValueId) || props.group.pid === newValueId
      if (isContained) {
        expandedKeys.value = { [`${props.group.id}-${props.spaceId}`]: true }
      } else {
        expandedKeys.value = {}
      }
    }
  },
)

const getNodes = () => {
  return [
    {
      key: `${props.group.id}-${props.spaceId}`,
      label: `${props.group.name} (${props.group.devices.length})`,
      type: 'group',
      data: props.group,
      children: props.group.devices.map((devicePid: any) => {
        const realDevice = store.getDeviceInSpaceById(props.spaceId, devicePid)
        return {
          label: realDevice.name,
          type: 'device',
          data: realDevice,
        }
      }),
    },
  ]
}

const handleClickGroup = (group: any) => {
  emit(isLinkedGroup(group.node) ? 'deselectGroup' : 'selectGroup', group.node.data)
}

const handleClickDevice = (device: any) => {
  emit(isLinkedDevice(device.node) ? 'deselectDevice' : 'selectDevice', device.node.data)
}

const isLinkedGroup = (node: any) => {
  if (!node) return false

  if (node.type === 'group') {
    const selected = store.isGroupLinked(node.data)
    return selected
  }

  return false
}

const isLinkedDevice = (node: any) => {
  if (!node) return false

  if (node.type === 'device') {
    const selected = store.isDeviceLinked(node.data as Device)
    return selected
  }

  return false
}

const isSelectedFeature = (pid: string) => {
  return store.selectedFeature?.feature.id.split(':')[1] === pid
}
</script>
<template>
  <Tree :value="getNodes()" :expandedKeys="expandedKeys">
    <template #default="{ node }">
      <div class="root-element" :class="{ selected: isSelectedFeature(node.data.pid) }">
        <span class="label"><i class="pi pi-th-large" style="margin-right: 4px" /> {{ node.label }}</span>
        <a
          :title="isLinkedGroup(node) ? 'Remove group' : 'Add group'"
          href=""
          @click.prevent="handleClickGroup({ node })"
        >
          <i :class="isLinkedGroup(node) ? 'pi pi-trash unlink' : 'pi pi-plus link'" />
        </a>
      </div>
    </template>
    <template #device="slotProps">
      <div class="device" :class="{ selected: isSelectedFeature(slotProps.node.data.pid) }">
        <span class="label">{{ slotProps.node.label }}</span>
        <a href="" @click.prevent="handleClickDevice(slotProps)">
          <i
            :title="isLinkedDevice(slotProps.node) ? 'Remove device' : 'Add device'"
            :class="isLinkedDevice(slotProps.node) ? 'pi pi-trash unlink' : 'pi pi-plus link'"
          />
        </a>
      </div>
    </template>
  </Tree>
</template>
<style scoped>
.link {
  color: var(--p-blue-600);
  margin-left: var(--section-padding);
  transition: color 0.3s;
}

.unlink {
  color: var(--p-red-500);
  margin-left: var(--section-padding);
  transition: color 0.3s;
}

.root-element.selected {
  font-weight: 600;
}

.root-element a {
  position: absolute;
  right: 0;
}

.device .label {
  display: inline-block;
  margin-right: 24px;
  line-height: 1.5;
  font-size: 12px;
}

.device a {
  position: absolute;
  right: 0;
}

.device.selected {
  font-weight: 600;
}
</style>
