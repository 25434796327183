<script setup lang="ts">
import { ref } from 'vue'
import MenuItem from './MenuItem.vue'
import NestedMenu from './NestedMenu.vue'
import NestedMenuItem from './NestedMenuItem.vue'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()

const nestedMenuVisble = ref(null)

const handleItemClick = (routeName: string, hasNestedMenu: boolean = false) => {
  if (hasNestedMenu) {
    nestedMenuVisble.value = nestedMenuVisble.value === routeName ? null : routeName
    return
  }

  router.push({
    name: routeName,
    params: route.params,
  })
}

const isActive = (routeName: string) => (route.name as string).startsWith(routeName)
</script>

<template>
  <div :class="{ overlay: true, visible: nestedMenuVisble }" @click="nestedMenuVisble = false"></div>
  <nav @click="nestedMenuVisble = false">
    <header>
      <img src="/on.svg" alt="" />
    </header>
    <ul>
      <MenuItem
        @click.stop="() => handleItemClick('floorplan-manager-selected')"
        :active="isActive('floorplan-manager')"
        icon="cog"
        label="Editor"
      ></MenuItem>
      <MenuItem
        @click.stop="() => handleItemClick('layers', true)"
        :active="isActive('layers') || nestedMenuVisble === 'layers'"
        icon="clone"
        label="Layers"
      >
        <NestedMenu :visible="nestedMenuVisble === 'layers'">
          <NestedMenuItem
            icon="pi-lightbulb"
            title="Device Control"
            subtitle="Adjust devices in real time"
            @click.stop="handleItemClick('layers-device-control')"
          ></NestedMenuItem>
          <NestedMenuItem
            icon="pi-sliders-h"
            title="Sensor Control"
            subtitle="Adjust sensor configuration"
            @click.stop="handleItemClick('layers-sensor-control')"
          ></NestedMenuItem>
        </NestedMenu>
      </MenuItem>
    </ul>
  </nav>
</template>

<style scoped>
nav {
  width: 64px;
  border-right: 1px solid #bdbdbd;
  color: #111;
  background: var(--primary-color);
}

nav header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
}

nav header img {
  width: 26px;
  height: 24px;
}

nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 63px;
  z-index: 999;
  display: none;
}
.overlay.visible {
  display: inherit;
}
</style>
