interface FuzzyFilterOptions {
  items: any[]
  key: string
  query: string
}

function calculateMatchScore(text: string, query: string): number {
  text = text.toLowerCase()
  query = query.toLowerCase()

  if (text.includes(query)) {
    return 0
  }

  let score = 0
  let textIndex = 0
  let queryIndex = 0

  while (textIndex < text.length && queryIndex < query.length) {
    if (text[textIndex] === query[queryIndex]) {
      queryIndex++
    } else {
      score++
    }
    textIndex++
  }

  // Add remaining characters in query to score
  score += query.length - queryIndex

  return score
}

export function fuzzyFilter({ items, key, query }: FuzzyFilterOptions): any[] {
  if (!query) return items
  const filtered = items
    .filter((item) => item[key])
    .map((item) => ({
      item,
      score: calculateMatchScore(item[key] ?? '', query),
    }))
    .filter((entry) => entry.score <= query.length)
    .sort((a, b) => a.score - b.score)
    .map((entry) => entry.item)

  return filtered
}
