import { createLogger } from '@/utils/logger'
import Pusher, { Channel } from 'pusher-js'

const logger = createLogger('messageReceiver')

interface ConnectionConfig {
  appKey: string
  cluster: string
}

type MessageHandler = (eventName: string, message: any) => void
type SubscriptionSucceedHandler = () => void

let channel: Channel | undefined
let pusher: Pusher | undefined
let pusherConfig: ConnectionConfig

export function isMessageReceiverInitialized() {
  return pusher !== undefined
}

export function startMessageReceiver(
  config: ConnectionConfig,
  channelName: string,
  subscriptionSucceedHandler: SubscriptionSucceedHandler,
  messageHandler: MessageHandler,
): void {
  pusherConfig = config

  logger.debug('Starting MessageReceiver')
  connectToChannel(channelName, subscriptionSucceedHandler, messageHandler)
}

export function changeMessageReceiverChannel(
  channelName: string,
  subscriptionSucceedHandler: SubscriptionSucceedHandler,
  messageHandler: MessageHandler,
): void {
  logger.debug(`Changing chanel from ${channel?.name} to ${channelName}`)
  stopMessageReceiver()
  connectToChannel(channelName, subscriptionSucceedHandler, messageHandler)
}

function connectToChannel(
  channelName: string,
  subscriptionSucceedHandler: SubscriptionSucceedHandler,
  messageHandler: MessageHandler,
): void {
  logger.debug('Subscribing to channel:', channelName)
  channel = getPusher().subscribe(channelName)

  if (subscriptionSucceedHandler) {
    channel.bind('pusher:subscription_succeeded', () => {
      subscriptionSucceedHandler()
    })
  }

  channel.bind_global(async (eventName: string, data: any) => {
    logger.debug(`Receiving message on channel: '${channelName}' with eventName: '${eventName}'`, data)
    // An alternative is eventName might be used to know which layer of the map should be updated
    if (eventName.startsWith('dvp:')) {
      messageHandler(eventName, data)
    }
  })
}

export function stopMessageReceiver(): void {
  logger.debug('Unsubscribing from channel:', channel?.name)
  channel?.unbind_all()
  channel?.unsubscribe()
}

function getPusher() {
  if (!pusher) {
    pusher = new Pusher(pusherConfig.appKey, { cluster: pusherConfig.cluster })
  }

  return pusher
}
