<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, watch, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import http from '@/modules/http.ts'
import BasePage from '@/components/BasePage.vue'
import FloorplanList from '@/components/FloorplanList/index.vue'
import SpaceCard from './components/SpaceCard/index.vue'
import SpaceCardSkeleton from './components/SpaceCardSkeleton.vue'
import SpaceDetails from './components/SpaceDetails/index.vue'
import SearchInput from '@/components/SearchInput.vue'
import store from './store'
import { Floorplan, ImageProcessingStatus } from '@/entities/Floorplan'

import clockIcon from '@/assets/clock.svg'
import { createLogger } from '@/utils/logger'
import { removeMap, createMap, redrawMap } from './editor'
import { useToast } from 'primevue/usetoast'
import {
  startMessageReceiver,
  changeMessageReceiverChannel,
  stopMessageReceiver,
  isMessageReceiverInitialized,
} from '@/modules/messageReceiver.js'
import locationStore from '@/stores/location'

const logger = createLogger('layer-control:index')

defineOptions({
  name: 'LayerControl',
})

const route = useRoute()
const router = useRouter()
const toast = useToast()
const isPageLoading = ref(true)
const isFloorplanLoading = ref(false)
const reload = ref(false)
const isUpdatingGroupStateChanges = ref(false)

const leafletElement = ref(null)

watch(
  () => route.params.floorplanId,
  async (newId) => {
    isFloorplanLoading.value = true

    await fetchInitialData()

    if (route.matched.some((route) => route.name === 'floorplan-manager')) {
      goToFloorplan(store.floorplans[0])
    }

    isFloorplanLoading.value = false
    await openFloorplan(newId as string)
  },
)

onMounted(async () => {
  const { floorplanId } = route.params

  if (!floorplanId) {
    goToFloorplan(store.floorplans[0])
  }

  await fetchInitialData()

  if (!store.hasFloorplans()) {
    logger.debug(`Location has no floorplans yet`)
    isPageLoading.value = false
    return
  }

  isPageLoading.value = false
  await openFloorplan(floorplanId as string)
  logger.debug(`State after initialization:`, store)
})

onBeforeUnmount(() => {
  stopMessageReceiver()
})

async function fetchInitialData() {
  const { floorplans, activeFloorplan } = await http.get<{ floorplans: Floorplan[]; activeFloorplan: Floorplan }>(
    `/locations/${route.params.locationId}/layers/device-control/floorplans/${route.params.floorplanId}/fetchInitialData`,
  )
  store.setFloorplans(floorplans)
  store.setActiveFloorplan(activeFloorplan)
  logger.debug(`State after fetchInitialData:`, activeFloorplan.spaces)
}

async function openFloorplan(floorplanId: string) {
  if (floorplanId) {
    await connectMessageReceiver()

    removeMap()
    nextTick(() => {
      createMap(leafletElement)
      redrawMap(store.features)
    })
  }
}

async function connectMessageReceiver() {
  try {
    if (isMessageReceiverInitialized()) {
      changeMessageReceiverChannel(getMessageChannelName(), initDevicesStates, messageHandler)
    } else {
      const pubsubConfig: any = await http.post(`/pubsub/config`)
      startMessageReceiver(pubsubConfig, getMessageChannelName(), initDevicesStates, messageHandler)
    }
  } catch (e) {
    logger.error('Error connecting to notification service', e)
    toast.add({
      summary: `Error connecting to notification service`,
      detail: `${e.message}`,
      life: 1300,
      severity: 'error',
    })
  }
}

function getMessageChannelName() {
  return `${route.params.locationId}-${route.params.floorplanId}-deviceControl`
}

function initDevicesStates() {
  http.post(
    `/locations/${route.params.locationId}/layers/device-control/${route.params.floorplanId}/triggerInitialState`,
  )
}

function messageHandler(_eventName: string, data: any) {
  store.setFeatures(data.features, data.statuses)
  redrawMap(store.features)
  reload.value = true
}

function isImageAvailable() {
  return store.activeFloorplan?.image_processing_status === ImageProcessingStatus.Ready
}

function goToFloorplan(floorplan: Floorplan) {
  router.push({
    name: 'layers-device-control',
    params: { locationId: route.params.locationId, floorplanId: floorplan.uuid },
  })
}

function handleSearch(e) {
  store.setSearchValue((e.target as HTMLInputElement).value)
}

const handleDimChange = async (change) => {
  handleChange(change, 'Dim Level')
}
const handleOnOffChange = async (change) => {
  handleChange(change, 'On/Off')
}

const handleChange = async (change, changeType) => {
  try {
    const result: { features: any; statuses: any } = await http.put(
      `/locations/${route.params.locationId}/layers/device-control/${route.params.floorplanId}/deviceState`,
      change,
    )

    store.setFeatures(result.features, result.statuses)
    reload.value = true
    redrawMap(store.features)

    toast.add({
      summary: `Device ${changeType} updated`,
      detail: change.deviceName,
      life: 1300,
      severity: 'success',
    })
  } catch (e) {
    logger.error(`Error updating device ${e}`)
    toast.add({
      summary: `Error updating device ${changeType}`,
      detail: `${e.message}`,
      life: 1300,
      severity: 'error',
    })
  }
}

const handleGroupStateChange = async (changes: any) => {
  const group = store.getSelectedGroup()
  isUpdatingGroupStateChanges.value = true

  const payload = [{ name: 'dim', value: Math.ceil((changes.dim * 255) / 100) }]

  try {
    await http.post(`/groups/${group.pid}/state`, payload)

    toast.add({
      summary: 'Success',
      detail: `Group status updated`,
      life: 2000,
      severity: 'success',
    })
    isUpdatingGroupStateChanges.value = false
  } catch (e) {
    logger.error(`Error with params: ${JSON.stringify(payload)}: `, e)
    isUpdatingGroupStateChanges.value = false
    toast.add({
      summary: 'Error while updating group status',
      detail: `${e.message}`,
      life: 2000,
      severity: 'error',
    })
  }
}
</script>

<template>
  <BasePage title="Device Control Layer" :location="locationStore.location">
    <template #sidebar>
      <section class="sidebar-content">
        <FloorplanList
          :loading="isPageLoading"
          :floorplans="store.floorplans"
          :activeFloorplan="store.activeFloorplan"
          @floorplanSelected="goToFloorplan"
        />
        <h2>Spaces</h2>
        <SearchInput @keyup="handleSearch" />

        <div class="spaces-list">
          <template v-if="isPageLoading">
            <SpaceCardSkeleton />
            <SpaceCardSkeleton />
            <SpaceCardSkeleton />
          </template>
          <template v-else>
            <SpaceCard v-if="store.spaces.length" v-for="space in store.spaces" :space="space" class="space-card" />
            <p v-else>No spaces linked</p>
          </template>
        </div>
      </section>
    </template>
    <template #main>
      <div class="main-content">
        <SpaceDetails
          :space="store.getSelectedSpace()"
          :group="store.getSelectedGroup()"
          :isLoading="isUpdatingGroupStateChanges"
          v-if="Boolean(store.selectedFeature)"
          @dim-changed="handleDimChange"
          @on-off-changed="handleOnOffChange"
          @on-group-state-changed="handleGroupStateChange"
        />
        <div class="main-right-content">
          <div v-if="isPageLoading || isFloorplanLoading" class="main-loading">
            <i class="pi pi-spin pi-spinner"></i>
          </div>
          <div v-else-if="!isImageAvailable()" class="converting-floorplan-image">
            <img :src="clockIcon" width="100" alt="clock" />
            Your floor plan is being created. It will be ready soon.
          </div>
          <div
            v-show="(!isPageLoading || !isFloorplanLoading) && store.floorplans.length && isImageAvailable()"
            ref="leafletElement"
            class="leaflet"
          ></div>
        </div>
      </div>
    </template>
  </BasePage>
</template>

<style scoped>
section.sidebar-content {
  display: flex;
  flex-direction: column;
  gap: var(--section-padding);
  height: 100%;
}

.search-input {
  width: 100%;
}

section.sidebar-content .spaces-list {
  overflow-y: auto;
  overflow-x: visible;
  margin: 2px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-grow: 1;
}

section.footer {
  flex: 0.1;
  max-height: 35px;
}

.save-button {
  width: 100%;
  display: flex;
  justify-content: center;
}
.leaflet {
  height: 100%;
  background-color: #fff;
}
.main-loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--p-surface-300);
}
.main-loading i {
  font-size: 32px;
}
.no-floorplans {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
}
.converting-floorplan-image {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
}

.space-card {
  cursor: pointer;
}

.main-content {
  display: flex;
  height: 100%;
  width: 100%;
}

.main-right-content {
  flex-grow: 1;
  transition: flex-grow 0.3s ease;
}

.main-content-enter-active .main-right-content,
.main-content-leave-active .main-right-content {
  transition: flex-grow 0.3s ease;
}

.main-content-enter .main-right-content,
.main-content-leave-to .main-right-content {
  flex-grow: 0;
}
</style>
