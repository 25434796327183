<script lang="ts" setup>
import Panel from 'primevue/panel'
import { defineProps, defineExpose } from 'vue'

defineProps({
  selected: {
    type: Boolean,
    default: false,
  },
})
defineExpose()
</script>

<template>
  <Panel v-bind="$attrs" class="card-container" :class="{ selected }">
    <template #togglericon="{ collapsed }">
      <slot name="togglericon" :collapsed="collapsed">
        <span class="pi pi-chevron-up" v-if="collapsed"></span>
        <span class="pi pi-chevron-down" v-else></span>
      </slot>
    </template>
    <section class="container">
      <slot />
    </section>
  </Panel>
</template>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.card-container.selected ::v-deep .p-panel-header {
  background: #2d4263;
  border-radius: 5px 5px 0 0;
  color: #fff;
  margin-bottom: 16px;
}

.card-container ::v-deep .p-panel-title {
  /* Improved readability in multi-line titles */
  line-height: 1.5;
}

.card-container.selected ::v-deep .p-panel-toggle-button {
  color: #27bec4;
}

.card-container.selected ::v-deep .p-panel-toggle-button:hover {
  color: #27bec4;
  background-color: #38537e;
}
</style>
