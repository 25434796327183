<script lang="ts" setup>
import { ref, defineProps, defineEmits, watch, onBeforeMount } from 'vue'
import { useRoute } from 'vue-router'
import { useToast } from 'primevue/usetoast'
import { LayerControlSpace } from '@/pages/LayerSensorControl/store'
import Button from 'primevue/button'
import InputMask from 'primevue/inputmask'
import SensorTargetCard from '../SensorTargetCard/index.vue'
import store from '@/pages/LayerSensorControl/store'
import DimLevel from '@/components/Control/DimLevel.vue'
import SearchInput from '@/components/SearchInput.vue'
import { getSensorTargetsForSpace, getSpaceTargetsConfiguration } from './helpers'
import http from '@/modules/http.ts'
import { selectSpace } from '@/pages/LayerSensorControl/editor'
import { secondsToTimeMask, timeMaskToSeconds, toDimLevel, toPercentage } from '@/modules/sensors'

const route = useRoute()
const toast = useToast()

defineOptions({
  name: 'SpaceDetails',
})

const props = defineProps<{
  space: LayerControlSpace
}>()

const bulkControlExpanded = ref(false)
const spaceDimLevel1 = ref(0)
const spaceDimLevel2 = ref(0)
const spaceTimeDelay1 = ref('')
const spaceTimeDelay2 = ref('')
const isSavingConfiguration = ref(false)

const searchValue = ref('')
const emit = defineEmits(['dimChanged', 'onOffChanged'])

watch(
  () => store.selectedFeature.feature,
  (newValue) => {
    if (newValue) {
      bulkControlExpanded.value = props.space.pid === newValue.id.split(':')[1]
      initializeSpaceProperties()
    }
  },
)

onBeforeMount(() => {
  bulkControlExpanded.value = props.space.pid === store.selectedFeature.feature.id.split(':')[1]
  initializeSpaceProperties()
})

const initializeSpaceProperties = () => {
  const initialSpaceProperties = getSpaceTargetsConfiguration(props.space)
  spaceDimLevel1.value = toPercentage(initialSpaceProperties.motion_level)
  spaceDimLevel2.value = toPercentage(initialSpaceProperties.dim_level)
  spaceTimeDelay1.value = secondsToTimeMask(initialSpaceProperties.time_delay_1 || 0)
  spaceTimeDelay2.value = secondsToTimeMask(initialSpaceProperties.time_delay_2 || 0)
}

const handleSelectSpace = () => {
  bulkControlExpanded.value = !bulkControlExpanded.value
  if (bulkControlExpanded.value) {
    selectSpace(props.space.pid)
  }
  return bulkControlExpanded
}

const sendConfiguration = async ({ targets, configuration }) => {
  isSavingConfiguration.value = true
  const payload = {
    targets,
    configurations: configuration,
  }
  try {
    await http.post(
      `/locations/${route.params.locationId}/layers/sensor-control/floorplans/${route.params.floorplanId}/configurations`,
      payload,
    )

    toast.add({
      summary: 'Success',
      detail: `Configuration change request sent to ${targets.length} targets`,
      life: 2000,
      severity: 'success',
    })
  } catch (e) {
    toast.add({
      summary: 'Error while saving configuration',
      detail: `${e.message}`,
      life: 2000,
      severity: 'error',
    })
  } finally {
    isSavingConfiguration.value = false
  }
}

const handleApplyConfig = async () => {
  const targets = getSensorTargetsForSpace(props.space).map((sensorTarget) => sensorTarget.target)
  const configuration = {
    motion_level: toDimLevel(spaceDimLevel1.value),
    dim_level: toDimLevel(spaceDimLevel2.value),
    time_delay_1: timeMaskToSeconds(spaceTimeDelay1.value) || 0,
    time_delay_2: timeMaskToSeconds(spaceTimeDelay2.value) || 0,
  }

  await sendConfiguration({ targets, configuration })
}
</script>

<template>
  <section class="container">
    <h2>
      {{ space?.name }}
      <i
        class="bulk-control-toggle pi"
        :class="{ 'pi-chevron-down': bulkControlExpanded, 'pi-chevron-up': !bulkControlExpanded }"
        @click="handleSelectSpace()"
      ></i>
    </h2>
    <p>Expand to modify all sensors in the space</p>
    <section v-show="bulkControlExpanded" class="bulk-controls">
      <fieldset>
        <label>Dim Level 1</label>
        <DimLevel
          v-model="spaceDimLevel1"
          :disabled="isSavingConfiguration"
          @sliderChanged="(value) => (spaceDimLevel1 = value)"
        />
      </fieldset>
      <fieldset>
        <label>Dim Level 2</label>
        <DimLevel
          v-model="spaceDimLevel2"
          :disabled="isSavingConfiguration"
          @sliderChanged="(value) => (spaceDimLevel2 = value)"
        />
      </fieldset>
      <fieldset>
        <label>Time Delay 1</label>
        <InputMask
          fluid
          v-model="spaceTimeDelay1"
          :disabled="isSavingConfiguration"
          mask="99h:99m:99s"
          placeholder="hh:mm:ss"
        />
      </fieldset>
      <fieldset>
        <label>Time Delay 2</label>
        <InputMask
          fluid
          v-model="spaceTimeDelay2"
          :disabled="isSavingConfiguration"
          mask="99h:99m:99s"
          placeholder="hh:mm:ss"
        />
      </fieldset>
      <Button
        label="Apply configuration"
        :loading="isSavingConfiguration"
        @click="handleApplyConfig"
        :disabled="isSavingConfiguration || getSensorTargetsForSpace(props.space).length === 0"
      />
    </section>
    <hr />
    <h2>Target Zones ({{ getSensorTargetsForSpace(space).length }})</h2>
    <template v-if="getSensorTargetsForSpace(space).length">
      <SearchInput v-model="searchValue" />
      <SensorTargetCard
        v-for="{ target, sensors } of getSensorTargetsForSpace(space)"
        :target="target"
        :sensors="sensors"
        :apply-button-loading="isSavingConfiguration"
        :disabled="isSavingConfiguration"
        @configuration-applied="sendConfiguration"
      />
    </template>
    <p v-else>No targets were found for this space</p>
  </section>
</template>

<style scoped>
hr {
  border: 1px solid #e0e0e0;
  width: 100%;
}
h2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
p {
  font-size: 12px;
}
label {
  display: inline-block;
  font-size: 12px;
  margin-bottom: 10px;
}
section.container {
  height: 100%;
  overflow-y: auto;
  padding: var(--section-padding);
  background-color: var(--p-surface-50);
  max-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.bulk-control-toggle {
  cursor: pointer;
  display: inline;
}
.bulk-controls {
  border: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
