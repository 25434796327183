<script setup lang="ts">
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import { ref, defineProps, PropType, defineEmits } from 'vue'
import { useRoute } from 'vue-router'
import { useToast } from 'primevue/usetoast'
import http from '@/modules/http.ts'

const props = defineProps({
  visible: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  floorplan: {
    type: Object,
    default: {},
  },
})

const emit = defineEmits(['close', 'deleted'])
const isDeleting = ref(false)
const toast = useToast()

const route = useRoute()

const confirmationText = ref('')

const handleDelete = async () => {
  if (!isConfirmationValid()) {
    return toast.add({
      summary: 'Confirmation Error',
      detail: 'Please type "delete" to confirm.',
      life: 1300,
      severity: 'error',
    })
  }

  isDeleting.value = true
  const locationId = route.params.locationId
  try {
    await http.del(`/locations/${locationId}/floorplans/${props.floorplan.uuid}`)
    isDeleting.value = false
    emit('deleted', props.floorplan)
    handleClose()
  } catch (e) {
    isDeleting.value = false
    return toast.add({
      summary: 'Error while deleting floorplan',
      detail: `${e.message}`,
      life: 1300,
      severity: 'error',
    })
  }
}

const handleClose = () => {
  confirmationText.value = null
  emit('close')
}

const isConfirmationValid = () => {
  return confirmationText.value === 'delete'
}
</script>

<template>
  <Dialog
    :style="{ width: '25vw' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    :visible="visible"
    v-on:update:visible="handleClose"
    modal
    header="Delete floor plan"
  >
    <div class="delete-confirmation">
      <p>Are you sure you want to delete this floor plan?</p>
      <p>This action cannot be undone and will remove all linked spaces and devices.</p>
      <div class="confirmation">
        <b>Type "delete" to confirm this action:</b>
        <InputText v-model="confirmationText" id="confirmationText" autofocus />
      </div>
    </div>

    <template #footer>
      <Button label="Cancel" text severity="secondary" @click="handleClose" />
      <Button
        :loading="isDeleting"
        severity="danger"
        label="Delete"
        :disabled="!isConfirmationValid()"
        @click="handleDelete"
      />
    </template>
  </Dialog>
</template>

<style scoped>
.delete-confirmation {
  margin-bottom: 20px;
}

#confirmationText {
  margin-top: 10px;
  width: 100%;
}

.confirmation {
  margin-top: 20px;
}
</style>
