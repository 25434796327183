import { reactive } from 'vue'
import { Feature, PolygonGeometry, PointGeometry } from '@/entities/Feature'
import { AvionLocationSpace, Device } from '@/entities/AvionLocation'
import { Floorplan } from '@/entities/Floorplan'
import { createLogger } from '@/utils/logger'
import { fuzzyFilter } from '@/modules/filter'
import { getDeviceFeatureId } from '@/modules/devices'
import { getSpaceFeatureId } from '@/modules/spaces'
import { getGroupFeatureId } from '@/modules/groups'
import { FloorplanWithSensors } from './entities/FloorplanWithSensors'

const logger = createLogger('sensor-control-layer:store')

export type AvionDeviceStateName = 'dim' | 'on_off' | 'white' | 'clock'

export type AvionDeviceState = {
  humanized: string
  name: AvionDeviceStateName
  value: string
  read: boolean
  write: boolean
  configuration: {
    min_cct?: number
    max_cct?: number
  }
  operable: string
  updated_at: string
}

export type LayerControlDevice = Device & {
  statuses: AvionDeviceState[]
}

export type LayerControlSpace = AvionLocationSpace & {
  devices: LayerControlDevice[]
}

let state = reactive<{
  floorplans: Floorplan[]
  activeFloorplan: FloorplanWithSensors | null
  filters: {
    searchValue: string
  }
  spaces: LayerControlSpace[]
  selectedFeature: {
    feature: Feature<PolygonGeometry | PointGeometry>
    type: 'space' | 'device' | 'group'
  }
}>({
  floorplans: [],
  activeFloorplan: null,
  filters: {
    searchValue: null,
  },
  spaces: [],
  selectedFeature: null,
})

export const createStore = () => {
  return {
    get floorplans() {
      return state.floorplans
    },
    get activeFloorplan() {
      return state.activeFloorplan
    },
    get features() {
      return state.activeFloorplan.data.features
    },
    get spaces() {
      const floorplanSpaces = state.spaces.filter((space) => {
        return state.activeFloorplan.data.spaces.includes(space.pid)
      })

      if (!state.filters.searchValue) return floorplanSpaces

      return fuzzyFilter({
        items: floorplanSpaces,
        key: 'name',
        query: state.filters.searchValue || '',
      })
    },
    get selectedFeature() {
      return state.selectedFeature
    },
    getSelectedSpace() {
      if (!state.selectedFeature) return

      const { feature, type } = state.selectedFeature
      const featureId = feature.id

      // Helper to find space by condition
      const findSpace = (condition: (space: any) => boolean) => state.spaces.find(condition)

      let space = null
      switch (type) {
        case 'space':
          space = findSpace((space) => getSpaceFeatureId(space) === featureId)
          break
        case 'group':
          space = findSpace((space) => space.groups.some((group) => getGroupFeatureId(group) === featureId))
          break
        case 'device':
          space = findSpace((space) => space.devices.some((device) => getDeviceFeatureId(device) === featureId))
          break
      }
      logger.debug('Resolved selected space', space, state.selectedFeature)
      return space
    },
    setFloorplans(floorplans: Floorplan[]) {
      logger.debug('Setting floorplans', floorplans)
      state.floorplans = floorplans
    },
    setFeatures(
      partialFeatures: Feature<PolygonGeometry | PointGeometry>[],
      partialDeviceStatuses: AvionDeviceState[],
    ) {
      state.activeFloorplan.data.features = state.activeFloorplan.data.features.map((feature) => {
        const change = partialFeatures.find((change) => change.id === feature.id)

        // merge properties
        if (change) {
          return {
            ...feature,
            properties: {
              ...feature.properties,
              ...change.properties,
            },
          }
        }

        // return feature as is if partial update does not include it
        return feature
      })

      // Update device statuses in each space
      //@ts-ignore
      state.spaces = state.spaces.map((space) => ({
        ...space,
        //@ts-ignore
        devices: space.devices.map((device) => {
          const deviceStatuses = partialDeviceStatuses[device.pid]

          if (!deviceStatuses) return device

          //@ts-ignore
          const updatedStatuses = device.statuses
            ? //@ts-ignore
              device.statuses.map((status: any) => {
                const match = deviceStatuses.find((ds: any) => ds.name === status.name)
                return match ? { ...status, value: match.value } : status
              })
            : deviceStatuses

          return { ...device, statuses: updatedStatuses }
        }),
      }))
    },
    setActiveFloorplan(floorplan: FloorplanWithSensors) {
      state.activeFloorplan = floorplan
      state.spaces = floorplan.spaces
    },
    setSelectedFeature(feature?: Feature<PolygonGeometry | PointGeometry>, type?: 'space' | 'device' | 'group') {
      logger.debug(`Called setSelectedFeature with`, feature)
      if (!feature) {
        state.selectedFeature = null
        return
      }
      state.selectedFeature = { feature, type }
      logger.debug(`Selected feature`, state.selectedFeature)
    },
    hasFloorplans() {
      return state.floorplans.length > 0
    },
    isDeviceLinked(device: Device) {
      const linkedDevices = state.activeFloorplan.data.devices
      return linkedDevices.includes(device.pid)
    },
    setSearchValue(searchValue: string) {
      state.filters.searchValue = searchValue
    },
    updateSensor(configuration) {
      const sensor = state.activeFloorplan.sensors.find((sensor) => sensor.pid === configuration.sensor_pid)
      sensor.configurations = {
        ...sensor.configurations,
        ...configuration,
      }
    },
  }
}
export default createStore()
