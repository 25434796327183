import { PolygonGeometry, PointGeometry, Feature } from './Feature'

interface FloorplanData {
  spaces: string[]
  devices: string[]
  groups: string[]
  features: Feature<PolygonGeometry | PointGeometry>[]
}

export enum ImageProcessingStatus {
  InProgress = 'in_progress',
  Ready = 'ready',
}

export type Floorplan = {
  id: number
  uuid: string
  name: string
  location_id: string
  data: FloorplanData
  created_at: string
  image: string
  image_url: string
  image_original: string
  image_original_url: string
  image_processing_status: ImageProcessingStatus
  image_height: number
  image_width: number
  spaces?: any[]
}
