import { reactive } from 'vue'
import { Feature, PolygonGeometry, PointGeometry } from '@/entities/Feature'
import { AvionLocation, AvionLocationSpace, Device, Group } from '@/entities/AvionLocation'
import { Floorplan } from '@/entities/Floorplan'
import { createLogger } from '@/utils/logger'
import { getSpaceFeatureId } from '@/modules/spaces'
import { getDeviceFeatureId } from '@/modules/devices'
import { getGroupFeatureId } from '@/modules/groups'

const logger = createLogger('floorplan-manager:store')

let state = reactive<{
  location: AvionLocation
  floorplans: Floorplan[]
  activeFloorplan: Floorplan | null
  spaces: AvionLocationSpace[]
  selectedFeature: {
    feature: Feature<PolygonGeometry | PointGeometry>
    type: 'space' | 'device' | 'group'
  }
  linkedSpaces: string[]
  linkedDevices: string[]
  linkedGroups: string[]
  hasChangesToSave: boolean
  filters: {
    searchValue: string
  }
}>({
  location: null,
  floorplans: [],
  activeFloorplan: null,
  spaces: [],
  selectedFeature: null,
  linkedSpaces: [],
  linkedDevices: [],
  linkedGroups: [],
  hasChangesToSave: false,
  filters: {
    searchValue: null,
  },
})

export const createStore = () => {
  return {
    get location() {
      return state.location
    },
    get spaces(): AvionLocationSpace[] {
      let filteredSpaces

      filteredSpaces = state.spaces.sort((a, b) => {
        const indexA = state.linkedSpaces.indexOf(a.pid)
        const indexB = state.linkedSpaces.indexOf(b.pid)

        // If both spaces are in linkedSpaces, sort them based on their index in linkedSpaces
        if (indexA !== -1 && indexB !== -1) {
          return indexA - indexB
        }

        // If only one space is in linkedSpaces, prioritize it
        if (indexA !== -1) return -1
        if (indexB !== -1) return 1

        // If neither space is in linkedSpaces, maintain their original order
        return 0
      })

      if (state.filters.searchValue) {
        filteredSpaces = filteredSpaces.filter((space) =>
          space.name.toLowerCase().includes(state.filters.searchValue?.toLowerCase()),
        )
      }

      return filteredSpaces
    },
    get floorplans() {
      return state.floorplans
    },
    get activeFloorplan() {
      return state.activeFloorplan
    },
    get features() {
      return state.activeFloorplan.data.features
    },
    get selectedFeature() {
      return state.selectedFeature
    },
    get hasChangesToSave() {
      return state.hasChangesToSave
    },
    getFloorplanById(floorplanId: string) {
      return state.floorplans.find((floorplan) => floorplan.uuid === floorplanId)
    },
    getDeviceInSpaceById(spaceId: string, deviceId: string) {
      const space = state.spaces.find((space) => space.pid === spaceId)
      const device = space.devices.find((device) => device.pid === deviceId)
      return device
    },
    setFloorplans(floorplans: Floorplan[]) {
      logger.debug('Setting floorplans', floorplans)
      state.floorplans = floorplans
    },
    updateFloorplan(floorplan: Floorplan) {
      logger.debug('Updating floorplan', floorplan)
      state.floorplans = state.floorplans.map((f) => (f.id === floorplan.id ? floorplan : f))
    },
    addFloorplan(floorplan: Floorplan) {
      logger.debug('Adding floorplan', floorplan)
      state.floorplans.unshift(floorplan)
    },
    removeFloorplan(floorplan: Floorplan) {
      logger.debug('Removing floorplan', floorplan)
      state.floorplans = state.floorplans.filter((f) => f.uuid !== floorplan.uuid)
    },
    hasFloorplans() {
      return state.floorplans.length > 0
    },
    setActiveFloorplanId(floorplanId: string) {
      const floorplan = state.floorplans.find((floorplan) => floorplan.uuid === floorplanId)
      if (!floorplan) return
      state.activeFloorplan = floorplan
      state.linkedSpaces = state.activeFloorplan.data.spaces
      state.linkedDevices = state.activeFloorplan.data.devices
      state.linkedGroups = state.activeFloorplan.data.groups || []
      logger.debug(`State after setting active floorplan`, state)
    },
    setLocation(location: AvionLocation) {
      logger.debug('Setting location', location)
      state.location = location
      state.spaces = location.spaces
    },
    setSearchValue(searchValue: string) {
      state.filters.searchValue = searchValue
    },
    // features
    addFeature(feature) {
      state.activeFloorplan.data.features.push(feature)
      state.hasChangesToSave = true
    },
    addLinkedSpace(space) {
      state.linkedSpaces.push(space.pid)
    },
    addLinkedDevice(device) {
      state.linkedDevices.push(device.pid)
    },
    addLinkedGroup(group) {
      state.linkedGroups.push(group.pid)
    },
    getDeviceSpace(device: Device) {
      return state.spaces.find((space) => space.devices.map((d) => d.pid).includes(device.pid))
    },
    removeFeatureForSpace(space: AvionLocationSpace) {
      state.linkedSpaces = state.linkedSpaces.filter((linkedSpace) => linkedSpace !== space.pid)
      state.activeFloorplan.data.features = state.activeFloorplan.data.features.filter(
        (feature) => feature.id !== getSpaceFeatureId(space),
      )
      state.hasChangesToSave = true
    },
    removeFeatureForDevice(device: Device) {
      state.linkedDevices = state.linkedDevices.filter((linkedDevice) => linkedDevice !== device.pid)
      state.activeFloorplan.data.features = state.activeFloorplan.data.features.filter(
        (feature) => feature.id !== getDeviceFeatureId(device),
      )
      state.hasChangesToSave = true
    },
    removeFeatureForGroup(group: Group) {
      state.linkedGroups = state.linkedGroups.filter((linkedDevice) => linkedDevice !== group.pid)
      state.activeFloorplan.data.features = state.activeFloorplan.data.features.filter(
        (feature) => feature.id !== getGroupFeatureId(group),
      )
      state.hasChangesToSave = true
    },
    isDeviceLinked(device: Device) {
      return state.linkedDevices.includes(device.pid)
    },
    isGroupLinked(group: Group) {
      return state.linkedGroups.includes(group.pid)
    },
    isSpaceLinked(space: AvionLocationSpace) {
      return state.linkedSpaces.includes(space.pid)
    },
    setSelectedFeature(feature?: Feature<PolygonGeometry | PointGeometry>, type?: 'space' | 'device' | 'group') {
      logger.debug(`Called setSelectedFeature with`, feature)
      if (!feature) {
        state.selectedFeature = null
        return
      }
      state.selectedFeature = { feature, type }
      logger.debug(`Selected feature`, state.selectedFeature)
    },
    updateFeature(updatedFeature) {
      state.activeFloorplan.data.features = state.activeFloorplan.data.features.map((feature) => {
        if (feature.id === updatedFeature.id) {
          return updatedFeature
        }
        return feature
      })
      state.hasChangesToSave = true

      logger.debug(
        `Features after updating feature ${updatedFeature.id} with coordinates ${updatedFeature.geometry.coordinates}`,
        state.activeFloorplan.data.features,
      )
    },
    changesSaved() {
      state.hasChangesToSave = false
    },
  }
}

export default createStore()
