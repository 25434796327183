import { createLogger } from '@/utils/logger'
const logger = createLogger('utils:auth')

type AvionEnv = 'localhost' | 'dev' | 'qa' | 'next' | 'stb' | 'prd'

export function getLoginUrl(url: string): string | null {
  try {
    const parsedUrl = new URL(url)
    const loginHostname = parsedUrl.hostname.replace('dashboard', 'login')

    return `${parsedUrl.protocol}//${loginHostname}?previous_url=${parsedUrl}`
  } catch (error) {
    console.error('Invalid URL:', url)
    return null
  }
}

export function getEnvironmentFromUrl(url): AvionEnv {
  let resolvedEnvironment: AvionEnv

  const environments: AvionEnv[] = ['localhost', 'dev', 'qa', 'next', 'stb', 'prd']

  environments.forEach((env) => {
    if (url.includes(`-${env}.avi-on.com`)) {
      resolvedEnvironment = env
    }
  })

  if (!resolvedEnvironment && url.includes(`localhost`)) {
    resolvedEnvironment = 'localhost'
  }

  if (!resolvedEnvironment) resolvedEnvironment = 'prd'

  logger.debug(`Resolved environment for url ${url}:`, resolvedEnvironment)

  return resolvedEnvironment
}

export function extractCredentials(cookieString: string, env: AvionEnv) {
  let cookieName

  if (env === 'prd') {
    cookieName = 'credentials'
  } else if (env === 'localhost') {
    // local development relies on data present in dev env for now
    cookieName = 'credentials-dev'
  } else {
    cookieName = `credentials-${env}`
  }

  const credentialsCookies = cookieString.split('; ').filter((cookie) => cookie.split('=')[0] === cookieName)
  logger.debug(`credentilsCookies resolved for cookie name: ${cookieName}`, credentialsCookies)

  if (!credentialsCookies.length) {
    logger.debug(`Could not find credentials for ${cookieName} cookie`)
    return false
  }

  const credentials = credentialsCookies[0].split('=')[1]

  try {
    return JSON.parse(decodeURIComponent(credentials))
  } catch (error) {
    console.error('Error parsing credentials:', error)
    return
  }
}
