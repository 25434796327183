<script setup lang="ts">
import { defineProps, defineEmits, watch, ref, PropType } from 'vue'
import { Device } from '@/entities/AvionLocation'
import store from '@/pages/FloorplanManager/store'
import Tree from 'primevue/tree'

const props = defineProps({
  spaceId: Number,
  devices: Array as PropType<Device[]>,
})

const emit = defineEmits(['selectElement', 'deselectElement'])

const expandedKeys = ref<{ [key: string]: boolean }>({})

const label = props.devices.length === 1 ? 'Ungrouped Device' : 'Ungrouped Devices'

watch(
  () => store.selectedFeature?.feature,
  (newValue) => {
    if (newValue) {
      const isContained = props.devices.map((e) => e.pid).includes(newValue.id.split(':')[1])
      if (isContained) {
        expandedKeys.value = { [`${props.spaceId}`]: true }
      } else {
        expandedKeys.value = {}
      }
    }
  },
)

const getNodes = () => {
  return [
    {
      key: `${props.spaceId}`,
      label: `${label} (${props.devices.length})`,
      type: `devices-group`,
      children: props.devices.map((component: any) => {
        return {
          label: component.name,
          type: 'device',
          data: component,
        }
      }),
    },
  ]
}

const isSelected = (node: any) => {
  const selected = store.isDeviceLinked(node.data as Device)
  return selected
}

const handleClickComponent = (slotProps: any) => {
  emit(isSelected(slotProps.node) ? 'deselectElement' : 'selectElement', slotProps.node.data)
}

const isSelectedFeature = (pid: string) => {
  return (
    store.selectedFeature &&
    store.selectedFeature.type === 'device' &&
    store.selectedFeature.feature.id === `device:${pid}`
  )
}
</script>

<template>
  <Tree :value="getNodes()" :expandedKeys="expandedKeys">
    <template #device="slotProps">
      <div class="device" :class="{ selected: isSelectedFeature(slotProps.node.data.pid) }">
        <span class="label">{{ slotProps.node.label }}</span>
        <a href="" @click.prevent="handleClickComponent(slotProps)">
          <i :class="isSelected(slotProps.node) ? 'pi pi-trash unlink' : 'pi pi-plus link'" />
        </a>
      </div>
    </template>
  </Tree>
</template>

<style scoped>
.link {
  color: var(--p-blue-600);
  margin-left: var(--section-padding);
  transition: color 0.3s;
}

.unlink {
  color: var(--p-red-500);
  margin-left: var(--section-padding);
  transition: color 0.3s;
}

.device .label {
  display: inline-block;
  margin-right: 24px;
  line-height: 1.5;
  font-size: 12px;
}

.device a {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.device.selected {
  font-weight: 600;
}
</style>
