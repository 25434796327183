<script setup lang="ts">
import { defineEmits, withDefaults } from 'vue'
import Panel from 'primevue/panel'
import Button from 'primevue/button'
import FloorplanItem from './FloorplanItem.vue'
import FloorplanItemSkeleton from './FloorplanItemSkeleton.vue'
import { Floorplan } from '@/entities/Floorplan'

withDefaults(
  defineProps<{
    floorplans: Floorplan[]
    activeFloorplan?: Floorplan
    loading?: boolean
    showCreate?: boolean
    showActions?: boolean
  }>(),
  {
    loading: false,
    showCreate: false,
    showActions: false,
  },
)

const emit = defineEmits(['floorplanSelected', 'createClicked', 'editFloorplan', 'deleteFloorplan'])

const selectFloorplan = (floorplan) => {
  emit('floorplanSelected', floorplan)
}

const handleCreateClick = () => {
  emit('createClicked')
}
</script>

<template>
  <Panel header="Floor Plans" toggleable class="floorplans">
    <template #togglericon="{ collapsed }">
      <span class="pi pi-chevron-up" v-if="collapsed"></span>
      <span class="pi pi-chevron-down" v-else></span>
    </template>
    <template #icons v-if="showCreate">
      <Button title="Add floorplan" severity="secondary" rounded text @click="handleCreateClick">
        <span class="pi pi-plus-circle"></span>
      </Button>
    </template>
    <ul class="floorplans-list" v-if="!loading && floorplans.length">
      <FloorplanItem
        v-for="floorplan in floorplans"
        :key="floorplan.uuid"
        :floorplan="floorplan"
        :is-active="floorplan.uuid === activeFloorplan?.uuid"
        :show-actions="showActions"
        @floorplan-selected="selectFloorplan(floorplan)"
        @delete-floorplan="(floorplan) => emit('deleteFloorplan', floorplan)"
        @edit-floorplan="(floorplan) => emit('editFloorplan', floorplan)"
      />
    </ul>
    <div class="empty-floorplan-list" v-else-if="!loading && !floorplans.length">No floorplans yet</div>
    <div v-else>
      <FloorplanItemSkeleton style="margin-top: 8px" :show-actions="showActions" />
      <FloorplanItemSkeleton :show-actions="showActions" />
    </div>
  </Panel>
</template>

<style scoped>
.floorplans {
  box-shadow: var(--card-shadow);
}

.floorplans ::v-deep .p-panel-content {
  border-top: 1px solid var(--p-surface-300);
  padding: 0;
}

.floorplans ul.floorplans-list {
  margin: 0;
  padding: 0;
  max-height: 100px;
  overflow-y: auto;
  list-style: none;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}
div.empty-floorplan-list {
  padding: 0 16px 8px 16px;
  font-size: 12px;
}
</style>
