<script lang="ts" setup>
import { ref, withDefaults, nextTick, watch } from 'vue'
import { secondsToTimeMask, toPercentage, toDimLevel, timeMaskToSeconds } from '@/modules/sensors'
import InputMask from 'primevue/inputmask'
import Panel from '@/components/Panel.vue'
import Button from 'primevue/button'
import DimLevel from '@/components/Control/DimLevel.vue'
import { type Sensor } from '../../entities/Sensor'
import Prism from 'prismjs'
import 'prismjs/themes/prism.css'
import 'prismjs/components/prism-json'
import store from '@/pages/LayerSensorControl/store'
import { selectDevice, selectGroup } from '@/pages/LayerSensorControl/editor'

defineOptions({
  name: 'SensorTargetCard',
})

const props = withDefaults(
  defineProps<{
    target: Sensor['target']
    sensors: Sensor[]
    disabled?: boolean
    applyButtonLoading?: boolean
  }>(),
  {
    disabled: false,
    applyButtonLoading: false,
  },
)

const emit = defineEmits(['configurationApplied'])

const dimLevel1 = ref(toPercentage(props.sensors[0].configurations['motion_level']))
const dimLevel2 = ref(toPercentage(props.sensors[0].configurations['dim_level']))
const timeDelay1 = ref(secondsToTimeMask(props.sensors[0].configurations['time_delay_1']))
const timeDelay2 = ref(secondsToTimeMask(props.sensors[0].configurations['time_delay_2']))

watch(
  () => props.sensors,
  () => {
    nextTick(() => {
      document.querySelectorAll('pre code').forEach((block) => {
        Prism.highlightElement(block)
      })
    })
  },
  { immediate: true },
)

const handleApplyConfiguration = async () => {
  const payload = {
    targets: [props.target],
    configuration: {
      motion_level: toDimLevel(dimLevel1.value),
      dim_level: toDimLevel(dimLevel2.value),
      time_delay_1: timeMaskToSeconds(timeDelay1.value) || 0,
      time_delay_2: timeMaskToSeconds(timeDelay2.value) || 0,
    },
  }

  emit('configurationApplied', payload)
}

const showSelected = () => {
  return (
    store.selectedFeature.type === props.target.type &&
    store.selectedFeature.feature.id.split(':')[1] === props.target.pid
  )
}

const handleSelectTarget = () => {
  if (props.target.type === 'device') {
    selectDevice(props.target.pid)
  }
  if (props.target.type === 'group') {
    selectGroup(props.target.pid)
  }
}
</script>

<template>
  <Panel
    v-if="target && sensors.length"
    class="card-container"
    toggleable
    :header="target.name"
    :class="{ selected: showSelected() }"
    :collapsed="!showSelected()"
    @click.stop="handleSelectTarget()"
  >
    <section class="container">
      <fieldset>
        <label>Dim Level 1</label>
        <div class="slider-container">
          <DimLevel @slider-changed="(value) => (dimLevel1 = value)" v-model="dimLevel1" :disabled="disabled" />
        </div>
      </fieldset>
      <fieldset>
        <label>Dim Level 2</label>
        <DimLevel @slider-changed="(value) => (dimLevel2 = value)" v-model="dimLevel2" :disabled="disabled" />
      </fieldset>
      <fieldset>
        <label>Time Delay 1</label>
        <InputMask fluid v-model="timeDelay1" mask="99h:99m:99s" placeholder="hh:mm:ss" :disabled="disabled" />
      </fieldset>
      <fieldset>
        <label>Time Delay 2</label>
        <InputMask fluid v-model="timeDelay2" mask="99h:99m:99s" placeholder="hh:mm:ss" :disabled="disabled" />
      </fieldset>
      <Button
        :loading="applyButtonLoading"
        :disabled="disabled"
        @click="handleApplyConfiguration"
        label="Apply configuration"
      />
      <details>
        <summary>Sensor debugging</summary>
        <pre>
          <code class="language-json">{{ sensors }}</code>
        </pre>
      </details>
    </section>
  </Panel>
</template>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}
label {
  font-size: 12px;
  display: inline-block;
  margin-bottom: 10px;
}
details {
  font-size: 12px;
  cursor: pointer;
}
details pre {
  background-color: var(--p-surface-50);
  padding: 8px;
  font-size: 10px;
  width: 100%;
  overflow: auto;
  max-height: 400px;
  white-space: normal;
}
</style>
