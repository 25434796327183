<script setup lang="ts">
import { ref, defineProps, defineEmits, watch } from 'vue'
import InputText from 'primevue/inputtext'
import IconField from 'primevue/iconfield'
import InputIcon from 'primevue/inputicon'

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: 'Search',
  },
})

const emit = defineEmits(['update:modelValue', 'input', 'change', 'blur', 'focus', 'keyup'])

const internalValue = ref(props.modelValue)

watch(
  () => props.modelValue,
  (newValue) => {
    internalValue.value = newValue
  },
)

const onInput = (event: Event) => {
  const value = (event.target as HTMLInputElement).value
  internalValue.value = value
  emit('update:modelValue', value)
  emit('input', event)
}

const onChange = (event: Event) => {
  emit('change', event)
}

const onBlur = (event: Event) => {
  emit('blur', event)
}

const onFocus = (event: Event) => {
  emit('focus', event)
}

const onKeyUp = (event: Event) => {
  emit('keyup', event)
}
</script>

<template>
  <IconField iconPosition="left">
    <InputIcon class="pi pi-search" />
    <InputText
      class="search-input"
      :placeholder="placeholder"
      v-model="internalValue"
      @input="onInput"
      @change="onChange"
      @blur="onBlur"
      @focus="onFocus"
      @keyup="onKeyUp"
    />
  </IconField>
</template>

<style scoped>
.search-input {
  width: 100%;
}
</style>
