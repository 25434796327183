<script setup lang="ts">
import { ref, defineEmits } from 'vue'
import { useToast } from 'primevue/usetoast'

const toast = useToast()

const filePath = ref('')
const fileContent = ref(null)
const imageWidth = ref(0)
const imageHeight = ref(0)
const imageMimeTypes = ['image/jpeg', 'image/jpg', 'image/gif', 'image/webp', 'image/bmp', 'image/png']
const acceptedFileTypes = '.bpm, .gif, .jpeg, .jpg, .png, .webp' // TODO: Add .pdf when requierd
const acceptedFileTypesToDisplay = '.png, .jpg, .webp'

defineProps({
  modelValue: Object,
})

const emit = defineEmits(['cancel', 'create', 'update:modelValue'])

const handleFileChange = (e) => {
  const file = e.target.files[0]
  handleFile(file)
}

const handleFileDrop = (event) => {
  event.preventDefault()
  const file = event.dataTransfer.files[0]
  handleFile(file)
}

const handleFile = (file) => {
  if (file) {
    if (!validFileType(file)) {
      return toast.add({
        summary: 'Unsupported image type',
        detail: `Accepted file types are ${acceptedFileTypesToDisplay}`,
        life: 1300,
        severity: 'error',
      })
    }

    const reader = new FileReader()
    reader.onload = (event) => {
      // Store the file content as a data URL
      fileContent.value = event.target.result
      // Get image width and height if the file is an image
      if (isImage(file)) {
        const img = new Image()
        img.onload = () => {
          imageHeight.value = img.height
          imageWidth.value = img.width
        }
        img.src = event.target.result as string
      }
    }

    reader.readAsDataURL(file)
    filePath.value = file.name
    emit('update:modelValue', { file: file, width: imageWidth, height: imageHeight })
  }
}

const validFileType = (file) => {
  return isImage(file) // || file.type === 'application/pdf' TODO when we support pdf files
}

const isImage = (file) => {
  return imageMimeTypes.includes(file.type)
}

const isPdfContent = (content) => {
  return content.startsWith('data:application/pdf;base64,')
}
</script>
<template>
  <div class="custom-div">
    <section>
      <div class="custom-file-input">
        <input
          @change="handleFileChange"
          type="file"
          name="floorplan"
          id="floorplanInput"
          :accept="acceptedFileTypes"
        />
        <label for="floorplanInput" class="custom-button"> <i class="pi pi-image"></i> Choose File </label>
      </div>
    </section>
    <section>
      <div class="drag-and-drop-section" @dragover.prevent @drop="handleFileDrop">
        <p>Or drag and drop image here</p>
        <p class="accepted-files">Accepted file types: {{ acceptedFileTypesToDisplay }}</p>
      </div>
    </section>
    <div v-if="fileContent">
      <!-- Conditional rendering based on file type -->
      <template v-if="isPdfContent(fileContent)">
        <object :data="fileContent" type="application/pdf" class="preview" :toolbar="false">PDF Preview</object>
      </template>
      <template v-else>
        <img :src="fileContent" alt="Floorplan Preview" class="preview" />
      </template>
    </div>
  </div>
</template>

<style scoped>
.preview {
  height: 300px;
  width: 100%;
}

section {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  gap: 0.3em;
}

/* examples */
.custom-file-input {
  display: flex;
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
  position: relative;
  overflow: hidden;
}

.custom-file-input input[type='file'] {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
}

.custom-button {
  background-color: transparent;
  color: #007bff;
  border: 1px solid #007bff;
  border-radius: 20px;
  padding: 8px 20px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}

.custom-button i {
  margin-right: 8px;
}

.custom-button:hover {
  background-color: #e6f2ff;
}

.custom-div {
  border: 1px dashed #e0e0e0;
  /* light gray dashed border */
  border-radius: 10px;
  /* rounded corners */
  padding: 20px;
  /* optional padding */
}

/* Drag and Drop section*/
.file-input-section,
.drag-and-drop-section {
  border: 2px dashed #ccc;
  border-radius: 10px;
  padding: 10px;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.accepted-files {
  color: #999;
}
</style>
