import { RouteRecordRaw } from 'vue-router'
import FloorplanManager from './pages/FloorplanManager/index.vue'
import LayerControl from './pages/LayerDeviceControl/index.vue'
import LayerSensorControl from './pages/LayerSensorControl/index.vue'

const redirectToEditor = (to) => {
  const { locationId } = to.params
  return { name: 'floorplan-manager', params: { locationId } }
}

export const routes: RouteRecordRaw[] = [
  { path: '/locations/:locationId/floorplans', component: FloorplanManager, name: 'floorplan-manager' },
  {
    path: '/locations/:locationId/floorplans/:floorplanId',
    component: FloorplanManager,
    name: 'floorplan-manager-selected',
  },
  {
    path: '/locations/:locationId/layers/device-control/:floorplanId',
    component: LayerControl,
    name: 'layers-device-control',
  },
  {
    path: '/locations/:locationId/layers/sensor-control/:floorplanId',
    component: LayerSensorControl,
    name: 'layers-sensor-control',
  },
  {
    path: '/locations/:locationId',
    redirect: redirectToEditor,
  },
  {
    path: '/:locationId',
    redirect: redirectToEditor,
  },
]
