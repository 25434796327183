import { Device } from '@/entities/AvionLocation'

export const showSpaceSelected = (space, { selectedFeature }): boolean => {
  if (!selectedFeature) return false
  return (
    selectedFeature.feature.id === `space:${space.pid}` ||
    Boolean(space.devices.find((d: Device) => `device:${d.pid}` === selectedFeature.feature.id)) ||
    Boolean(space.groups.find((d) => `group:${d.pid}` === selectedFeature.feature.id))
  )
}
