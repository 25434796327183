<script setup lang="ts">
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import { ref, defineProps, PropType, defineEmits, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useToast } from 'primevue/usetoast'
import http from '@/modules/http.ts'

const props = defineProps({
  visible: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  floorplan: {
    type: Object,
    default: {},
  },
})

const emit = defineEmits(['close', 'edited'])
const isDeleting = ref(false)
const toast = useToast()
let name = ref('')

const route = useRoute()

watch(
  () => props.visible,
  (newValue) => {
    if (newValue) {
      name.value = props.floorplan.name || ''
    }
  },
)

const handleEdit = async () => {
  try {
    isDeleting.value = true
    const locationId = route.params.locationId
    await http.put(`/locations/${locationId}/floorplans/${props.floorplan.uuid}`, {
      properties: { name: name.value },
    })

    isDeleting.value = false
    emit('edited', props.floorplan, { name: name.value })
    handleClose()
  } catch (e) {
    isDeleting.value = false
    return toast.add({
      summary: 'Error while editing floorplan',
      detail: `${e.message}`,
      life: 1300,
      severity: 'error',
    })
  }
}

const handleClose = () => {
  emit('close')
}
</script>

<template>
  <Dialog
    :style="{ width: '30vw' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    :visible="visible"
    v-on:update:visible="handleClose"
    modal
    header="Edit floor plan"
  >
    <div class="flex flex-column gap-2 mb-2">
      <label for="name">Floor plan name</label>
      <InputText v-model="name" id="name" :invalid="!name" autofocus />
    </div>
    <template #footer>
      <Button label="Cancel" text severity="secondary" @click="handleClose" />
      <Button :loading="isDeleting" label="Save" @click="handleEdit" />
    </template>
  </Dialog>
</template>

<style scoped></style>
