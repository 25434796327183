import axios, { AxiosError, InternalAxiosRequestConfig, AxiosResponse } from 'axios'
import { getLoginUrl } from '@/utils/auth'
import userStore from '@/stores/user.ts'

const client = axios.create({ baseURL: '/api' })

client.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const authToken = userStore.user.authToken
  if (authToken) {
    config.headers['Authorization'] = `Bearer ${authToken}`
  }
  return config
})

client.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      const loginUrl = getLoginUrl(window.location.href)
      loginUrl && (window.location.href = loginUrl)
    }
    throw error
  },
)

async function get<T>(url: string): Promise<T> {
  const response: AxiosResponse<T> = await client.get<T>(url)
  return response.data
}

async function post<T>(url: string, data?: any, config?: any): Promise<T> {
  const response: AxiosResponse<T> = await client.post<T>(url, data, config)
  return response.data
}

async function put<T>(url: string, config: any): Promise<T> {
  const response: AxiosResponse<T> = await client.put<T>(url, config)
  return response.data
}

async function del<T>(url: string): Promise<T> {
  const response: AxiosResponse<T> = await client.delete<T>(url)
  return response.data
}

const httpClient = {
  get,
  post,
  put,
  del,
}

export default httpClient
