<script setup lang="ts">
import { AvionLocationSpace, Device, Group } from '@/entities/AvionLocation'
import { defineProps, PropType, defineEmits } from 'vue'
import store from '@/pages/FloorplanManager/store'
import { showSpaceSelected } from './helpers'
import { useToast } from 'primevue/usetoast'
import { ImageProcessingStatus } from '@/entities/Floorplan'
import SelectionDeviceTree from '@/pages/FloorplanManager/components/SelectionDeviceTree/index.vue'
import SelectionGroupTree from '@/pages/FloorplanManager/components/SelectionGroupTree/index.vue'

const toast = useToast()

const props = defineProps({
  space: {
    type: Object as PropType<AvionLocationSpace>,
    required: true,
  },
  isLoading: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const emit = defineEmits(['add-space', 'remove-space', 'add-group', 'remove-group', 'add-device', 'remove-device'])

const getUngroupedDevices = () => {
  const groupedDevices = props.space.groups.flatMap((group) => group.devices)

  const ungroupedDevices = props.space.devices.filter((currentDevice) => {
    if (groupedDevices.length) {
      const currentId = currentDevice.pid
      return !groupedDevices.includes(currentId)
    }
    return true
  })

  return ungroupedDevices
}

const readOnly = () => {
  return (
    props.isLoading ||
    !store.floorplans.length ||
    store.activeFloorplan?.image_processing_status === ImageProcessingStatus.InProgress
  )
}

const handleAddDevice = (device: Device) => {
  if (!store.isSpaceLinked(props.space)) {
    toast.add({
      severity: 'warn',
      summary: `Unable to add device: ${device.name}`,
      detail: `Space '${props.space.name}' should be added first`,
      life: 1300,
    })
    return
  }

  const deviceGroup = props.space.groups.find((group) => group.devices.includes(device.pid))
  if (deviceGroup && !store.isGroupLinked(deviceGroup)) {
    toast.add({
      severity: 'warn',
      summary: `Unable to add device: ${device.name}`,
      detail: `Group '${deviceGroup.name}' should be added first`,
      life: 1300,
    })
    return
  }

  emit('add-device', device)
}

const handleRemoveDevice = (device: Device) => {
  emit('remove-device', device)
}

const handleAddGroup = (group: Group) => {
  if (!store.isSpaceLinked(props.space)) {
    toast.add({
      severity: 'warn',
      summary: `Unable to add group: ${group.name}`,
      detail: `Space '${props.space.name}' should be added first`,
      life: 1300,
    })
    return
  }
  emit('add-group', group)
}

const handleRemoveGroup = (group: Group) => {
  emit('remove-group', group)
}
</script>

<template>
  <article :class="{ selected: showSpaceSelected(space, store) }">
    <header>
      <h3><i class="pi pi-box" style="margin-right: 4px" /> {{ space.name }}</h3>
      <a @click.prevent="!readOnly() && $emit('add-space')" title="Add Space" v-if="!store.isSpaceLinked(space)">
        <i :class="!readOnly() && 'add-space pi pi-plus'"></i
      ></a>
      <i
        title="Remove Space"
        v-else
        @click="!readOnly() && $emit('remove-space')"
        :class="!readOnly() && 'pi pi-trash unlink'"
      ></i>
    </header>
    <p v-if="space.devices.length === 0">No Devices</p>
    <template v-else>
      <SelectionGroupTree
        v-for="group in space.groups"
        :group="group"
        :spaceId="space.pid"
        @select-device="handleAddDevice"
        @deselect-device="handleRemoveDevice"
        @select-group="handleAddGroup"
        @deselect-group="handleRemoveGroup"
      />
      <SelectionDeviceTree
        v-if="getUngroupedDevices().length"
        :devices="getUngroupedDevices()"
        :spaceId="space.id"
        @select-element="handleAddDevice"
        @deselect-element="handleRemoveDevice"
      />
    </template>
  </article>
</template>

<style scoped>
article {
  box-shadow: var(--card-shadow);
  border-radius: var(--border-radius);
  background-color: #fff;
  padding: var(--section-padding);
  border-bottom: 1px solid #eeeeee;
}

article.selected {
  background-color: var(--p-indigo-50);
  border: 1px solid var(--p-indigo-400);
}

article header {
  padding-bottom: var(--section-padding);
  margin-bottom: var(--section-padding);
  border-bottom: 1px solid #eeeeee;
  display: flex;
  justify-content: space-between;
}

article header i {
  transition: color 0.3s;
  color: var(--p-gray-600);
  cursor: pointer;
}

article header i.add-space:hover {
  color: var(--p-primary-color);
}

article h3 {
  margin: 0;
  font-size: 14px;
}

article a {
  cursor: pointer;
}

.link {
  color: var(--p-blue-600);
  margin-left: var(--section-padding);
  transition: color 0.3s;
}

.unlink {
  color: var(--p-red-500);
  margin-left: var(--section-padding);
  transition: color 0.3s;
}

article ::v-deep .p-tree {
  padding: 0;
  background-color: inherit;
}

article ::v-deep .p-tree-node-children {
  padding: 0;
  margin-top: 8px;
}

article ::v-deep .p-tree-node-leaf {
  position: relative;
}

article ::v-deep .p-tree-toggler {
  margin-right: 0;
}

article ::v-deep .p-tree-node-content {
  padding: 0;
  position: relative;
}

article ::v-deep .p-tree-node-toggler-icon {
  width: 12px;
  height: 12px;
}
</style>
