<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, watch, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import http from '@/modules/http.ts'
import BasePage from '@/components/BasePage.vue'
import FloorplanList from '@/components/FloorplanList/index.vue'
import SpaceCard from './components/SpaceCard/index.vue'
import SpaceCardSkeleton from './components/SpaceCardSkeleton.vue'
import SpaceDetails from './components/SpaceDetails/index.vue'
import SearchInput from '@/components/SearchInput.vue'
import store from './store'
import { FloorplanWithSensors } from './entities/FloorplanWithSensors'
import { Floorplan } from '@/entities/Floorplan'
import { createLogger } from '@/utils/logger'
import { removeMap, createMap, redrawMap } from './editor'
import { selectSpace } from './editor'
import { useToast } from 'primevue/usetoast'
import {
  startMessageReceiver,
  changeMessageReceiverChannel,
  stopMessageReceiver,
  isMessageReceiverInitialized,
} from '@/modules/messageReceiver.js'
import locationStore from '@/stores/location'

const logger = createLogger('layer-sensor-control:index')

defineOptions({
  name: 'LayerSensorControl',
})

const route = useRoute()
const router = useRouter()
const toast = useToast()
const isPageLoading = ref(true)
const isFloorplanLoading = ref(false)

const leafletElement = ref(null)

watch(
  () => route.params.floorplanId,
  async (newId) => {
    isFloorplanLoading.value = true

    await fetchInitialData()

    isFloorplanLoading.value = false
    await openFloorplan(newId as string)
  },
)

onMounted(async () => {
  const { floorplanId } = route.params

  if (!floorplanId) {
    goToFloorplan(store.floorplans[0])
  }

  await fetchInitialData()

  if (!store.hasFloorplans()) {
    logger.debug(`Location has no floorplans yet`)
    isPageLoading.value = false
    return
  }

  isPageLoading.value = false
  await openFloorplan(floorplanId as string)

  logger.debug(`State after initialization:`, store)
})

onBeforeUnmount(() => {
  stopMessageReceiver()
})

async function fetchInitialData() {
  const { floorplans, activeFloorplan } = await http.get<{
    floorplans: Floorplan[]
    activeFloorplan: FloorplanWithSensors
  }>(
    `/locations/${route.params.locationId}/layers/sensor-control/floorplans/${route.params.floorplanId}/fetchInitialData`,
  )

  store.setFloorplans(floorplans)
  store.setActiveFloorplan(activeFloorplan)
}

async function openFloorplan(floorplanId: string) {
  if (floorplanId) {
    await connectMessageReceiver()

    removeMap()
    nextTick(() => {
      createMap(leafletElement)
      redrawMap(store.features)
    })
  }
}

function goToFloorplan(floorplan: Floorplan) {
  router.push({
    name: 'layers-sensor-control',
    params: { locationId: route.params.locationId, floorplanId: floorplan.uuid },
  })
}

function handleSearch(e) {
  store.setSearchValue((e.target as HTMLInputElement).value)
}

async function connectMessageReceiver() {
  try {
    if (isMessageReceiverInitialized()) {
      changeMessageReceiverChannel(getMessageChannelName(), null, messageHandler)
    } else {
      const pubsubConfig: any = await http.post(`/pubsub/config`)
      startMessageReceiver(pubsubConfig, getMessageChannelName(), null, messageHandler)
    }
  } catch (e) {
    logger.error('Error connecting to notification service', e)
    toast.add({
      summary: `Error connecting to notification service`,
      detail: `${e.message}`,
      life: 1300,
      severity: 'error',
    })
  }
}

function getMessageChannelName() {
  return `${route.params.locationId}-${route.params.floorplanId}-sensorControl`
}

function messageHandler(_eventName: string, sensorData: any) {
  store.updateSensor(sensorData)

  const sensor = store.activeFloorplan.sensors.find((sensor) => sensor.pid === sensorData.sensor_pid)
  toast.add({
    summary: sensorData.success ? 'Success' : 'Error',
    detail: `Applied configuration in target ${sensor.target.name} for sensor ${sensorData.sensor_pid}`,
    life: 2000,
    severity: sensorData.success ? 'success' : 'error',
  })
}
</script>

<template>
  <BasePage title="Sensor Control Layer" :location="locationStore.location">
    <template #sidebar>
      <section class="sidebar-content">
        <FloorplanList
          :loading="isPageLoading"
          :floorplans="store.floorplans"
          :activeFloorplan="store.activeFloorplan"
          @floorplanSelected="goToFloorplan"
        />
        <h2>Spaces</h2>
        <SearchInput @keyup="handleSearch" />

        <div class="spaces-list">
          <template v-if="isPageLoading">
            <SpaceCardSkeleton />
            <SpaceCardSkeleton />
            <SpaceCardSkeleton />
          </template>
          <template v-else>
            <SpaceCard
              v-if="store.spaces.length"
              v-for="space in store.spaces"
              :space="space"
              @click.stop="selectSpace(space.pid)"
              class="space-card"
            />
            <p v-else>No spaces linked</p>
          </template>
        </div>
      </section>
    </template>
    <template #main>
      <div class="main-content">
        <SpaceDetails :space="store.getSelectedSpace()" v-if="Boolean(store.selectedFeature)" />
        <div class="main-right-content">
          <div v-if="isPageLoading || isFloorplanLoading" class="main-loading">
            <i class="pi pi-spin pi-spinner"></i>
          </div>
          <div
            v-show="(!isPageLoading || !isFloorplanLoading) && store.floorplans.length"
            ref="leafletElement"
            class="leaflet"
          ></div>
        </div>
      </div>
    </template>
  </BasePage>
</template>

<style scoped>
section.sidebar-content {
  display: flex;
  flex-direction: column;
  gap: var(--section-padding);
  height: 100%;
}

.search-input {
  width: 100%;
}

section.sidebar-content .spaces-list {
  overflow-y: auto;
  overflow-x: visible;
  margin: 2px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-grow: 1;
}

section.footer {
  flex: 0.1;
  max-height: 35px;
}

.save-button {
  width: 100%;
  display: flex;
  justify-content: center;
}
.leaflet {
  height: 100%;
  background-color: #fff;
}
.main-loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--p-surface-300);
}
.main-loading i {
  font-size: 32px;
}
.no-floorplans {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
}
.converting-floorplan-image {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
}

.space-card {
  cursor: pointer;
}

.main-content {
  display: flex;
  height: 100%;
  width: 100%;
}

.main-right-content {
  flex-grow: 1;
  transition: flex-grow 0.3s ease;
}

.main-content-enter-active .main-right-content,
.main-content-leave-active .main-right-content {
  transition: flex-grow 0.3s ease;
}

.main-content-enter .main-right-content,
.main-content-leave-to .main-right-content {
  flex-grow: 0;
}
</style>
