export function secondsToTimeMask(seconds) {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const secs = seconds % 60

  return `${String(hours).padStart(2, '0')}h:${String(minutes).padStart(2, '0')}m:${String(secs).padStart(2, '0')}s`
}

export function timeMaskToSeconds(timeStr) {
  const [hours, minutes, seconds] = timeStr.split(':').map((part) => parseInt(part, 10))

  return hours * 3600 + minutes * 60 + seconds
}

export function toPercentage(value) {
  if (value == null) return 0
  return Math.ceil((value / 255) * 100)
}

export function toDimLevel(value: number): number {
  if (value == null) return 0
  return Math.floor((value / 100) * 255)
}
