<script setup lang="ts">
import { ref, defineEmits, defineProps, withDefaults } from 'vue'
import { Floorplan } from '@/entities/Floorplan'
import Menu from 'primevue/menu'

const props = withDefaults(
  defineProps<{
    floorplan: Floorplan
    isActive: boolean
    showActions: boolean
  }>(),
  {
    showActions: false,
  },
)

const menu = ref()
const items = ref([
  {
    label: 'Edit',
    icon: 'pi pi-pencil',
    command: () => emit('editFloorplan', props.floorplan),
  },
  {
    label: 'Delete',
    icon: 'pi pi-trash',
    command: () => emit('deleteFloorplan', props.floorplan),
  },
])

const emit = defineEmits(['floorplanSelected', 'editFloorplan', 'deleteFloorplan'])
</script>

<template>
  <li :class="{ active: isActive }" @click="emit('floorplanSelected')">
    <label>{{ floorplan.name }}</label>
    <template v-if="showActions === true">
      <i
        aria-haspopup="true"
        @click.stop="(e) => menu.toggle(e)"
        aria-controls="overlay_menu"
        role="button"
        class="pi pi-cog"
      ></i>
      <Menu ref="menu" :model="items" :popup="true" />
    </template>
  </li>
</template>

<style scoped>
li {
  width: 100%;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  transition: background-color 0.3s;
}

li,
label {
  cursor: pointer;
  width: 100%;
}

li:hover,
li.active {
  background: #eff4ff;
}

li i {
  padding: 0 8px;
}
</style>
