<script setup lang="ts">
import { ref, onMounted, watch, nextTick } from 'vue'
import { useRoute, useRouter, onBeforeRouteUpdate, onBeforeRouteLeave } from 'vue-router'
import http from '@/modules/http.ts'
import BasePage from '@/components/BasePage.vue'
import FloorplanList from '@/components/FloorplanList/index.vue'
import store from './store'
import { Floorplan } from '@/entities/Floorplan'
import { AvionLocation, AvionLocationSpace, Device, Group } from '@/entities/AvionLocation'
import SpaceCard from './components/SpaceCard/index.vue'
import SpaceCardSkeleton from './components/SpaceCardSkeleton.vue'
import CreateFloorplanDialog from './components/CreateFloorplanDialog.vue'
import EditFloorplanDialog from './components/EditFloorplanDialog.vue'
import DeleteFloorplanDialog from './components/DeleteFloorplanDialog.vue'
import IconField from 'primevue/iconfield'
import InputIcon from 'primevue/inputicon'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import { useToast } from 'primevue/usetoast'
import { createLogger } from '@/utils/logger'
import { createMap, removeMap, redrawMap, addSpaceToMap, addGroupToMap, addDeviceToMap, finishEditing } from './editor'
import ConfirmDialog from 'primevue/confirmdialog'
import { useConfirm } from 'primevue/useconfirm'

const logger = createLogger('floorplan-manager:index')

const route = useRoute()
const router = useRouter()
const showCreateFloorplanDialog = ref(false)
const showEditFloorplanDialog = ref(false)
const showDeleteFloorplanDialog = ref(false)
const isPageLoading = ref(true)
const isFloorplanLoading = ref(false)
const isSaving = ref(false)
const floorplanActioned = ref(null)
const toast = useToast()
const confirmation = useConfirm()

const leafletElement = ref(null)

watch(
  () => route.params.floorplanId,
  async (newId) => {
    isFloorplanLoading.value = true
    const floorplan = await http.get<Floorplan>(`/locations/${route.params.locationId}/floorplans/${newId}`)
    store.updateFloorplan(floorplan)

    if (route.matched.some((route) => route.name === 'floorplan-manager')) {
      goToFloorplan(store.floorplans[0])
    }

    isFloorplanLoading.value = false
    store.setActiveFloorplanId(newId as string)

    removeMap()
    nextTick(() => {
      createMap(leafletElement)
      redrawMap(store.features)
    })
  },
)

onMounted(async () => {
  await fetchInitialData()
  const { floorplanId } = route.params

  if (!store.hasFloorplans()) {
    logger.debug(`Location has no floorplans yet`)
    isPageLoading.value = false
    return
  }

  if (!floorplanId) {
    goToFloorplan(store.floorplans[0])
  }

  isPageLoading.value = false
  store.setActiveFloorplanId(floorplanId as string)

  removeMap()
  nextTick(() => {
    createMap(leafletElement)
    redrawMap(store.features)
  })
  logger.debug(`State after initialization:`, store)
})

const hasSavedChangesNavigationGuard = (_to, _from, next) => {
  if (store.hasChangesToSave) {
    confirmation.require({
      message: 'You have unsaved changes. Do you really want to leave?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      rejectProps: {
        label: 'Leave without saving',
        severity: 'secondary',
        outlined: true,
      },
      acceptProps: {
        label: 'No, take me back',
        severity: 'primary',
      },
      accept: () => {
        next(false)
      },
      reject: () => {
        store.changesSaved()
        next()
      },
    })
  } else {
    next()
  }
}

onBeforeRouteLeave(hasSavedChangesNavigationGuard)
onBeforeRouteUpdate(hasSavedChangesNavigationGuard)

function handleFloorplanCreation(floorplan: Floorplan) {
  store.addFloorplan(floorplan)
  goToFloorplan(floorplan)
  showCreateFloorplanDialog.value = false
}

async function handleFloorplanEdition(floorplan: Floorplan, properties: { name: string }) {
  showEditFloorplanDialog.value = false
  floorplan.name = properties.name
}

async function handleFloorplanDeletion(floorplan: Floorplan) {
  store.removeFloorplan(floorplan)
  showDeleteFloorplanDialog.value = false
  if (floorplan.uuid === route.params.floorplanId) {
    // deleted currently active floorplan, let's open another one
    goToFloorplan(store.floorplans[0])
  }
}

async function fetchInitialData() {
  try {
    const { location, floorplans } = await http.get<{ location: AvionLocation; floorplans: Floorplan[] }>(
      `/locations/${route.params.locationId}/layers/floorplanManager/fetchInitialData`,
    )
    store.setLocation(location)
    store.setFloorplans(floorplans)
  } catch (e) {
    isPageLoading.value = false
    toast.add({
      severity: 'error',
      summary: 'Unable to load location data',
      detail: 'Please check the location ID in the URL',
    })
  }
}

function goToFloorplan(floorplan: Floorplan) {
  router.push({
    name: 'floorplan-manager-selected',
    params: { locationId: route.params.locationId, floorplanId: floorplan.uuid },
  })
}

async function handleSave() {
  isSaving.value = true
  finishEditing()

  try {
    await http.put(
      `/locations/${store.activeFloorplan.location_id}/floorplans/${store.activeFloorplan.uuid}/features`,
      {
        features: store.features,
      },
    )

    store.changesSaved()

    toast.add({
      summary: 'Success',
      detail: 'Changes saved correctly',
      severity: 'success',
      life: 1300,
    })
  } catch (e) {
    toast.add({
      summary: 'Error while saving floorplan',
      detail: `${e.message}`,
      life: 1300,
      severity: 'error',
    })
  }

  isSaving.value = false
}

function handleSearch(e) {
  store.setSearchValue((e.target as HTMLInputElement).value)
}

function createSpace(space: AvionLocationSpace) {
  addSpaceToMap(space, (feature) => {
    logger.debug(`Space feature created for ${space.id}`, feature)
    store.addFeature(feature)
    store.addLinkedSpace(space)
    logger.debug(`About to redraw map with features: ${store.features.map((f) => f.id)}`)
    redrawMap(store.features)
    logger.debug(`Finished redrawing map`)
    setTimeout(() => {
      document.getElementById(`space-card-${space.id}`).scrollIntoView({ behavior: 'smooth' })
    }, 100)
  })
}

function removeSpace(space: AvionLocationSpace) {
  confirmation.require({
    message: `You’re about to remove space '${space.name}'\n alongside its groups and devices. Please confirm`,
    header: 'Remove Space',
    icon: 'pi pi-info-circle',
    rejectLabel: 'Cancel',
    rejectProps: {
      label: 'Cancel',
      severity: 'secondary',
      outlined: true,
    },
    acceptProps: {
      label: 'Remove',
      severity: 'danger',
    },
    accept: () => {
      store.removeFeatureForSpace(space)
      space.devices.forEach((device) => removeDevice(device))
      space.groups.forEach((group) => store.removeFeatureForGroup(group))
      store.setSelectedFeature(null)
      logger.debug(`About to redraw map with features: ${store.features.map((f) => f.id)}`)
      redrawMap(store.features)
    },
  })
}

function createGroup(group: Group) {
  logger.debug('createGroup:', group)
  addGroupToMap(group, (feature) => {
    logger.debug(`Group feature created for ${group.id}`, feature)
    store.addFeature(feature)
    store.addLinkedGroup(group)
    logger.debug(`About to redraw map with features: ${store.features.map((f) => f.id)}`)
    redrawMap(store.features)
    logger.debug(`Finished redrawing map`, store)
  })
}

function removeGroup(group: Group) {
  confirmation.require({
    message: `You’re about to remove group '${group.name}'\n alongside its devices. Please confirm`,
    header: 'Remove Group',
    icon: 'pi pi-info-circle',
    rejectLabel: 'Cancel',
    rejectProps: {
      label: 'Cancel',
      severity: 'secondary',
      outlined: true,
    },
    acceptProps: {
      label: 'Remove',
      severity: 'danger',
    },
    accept: () => {
      store.removeFeatureForGroup(group)
      group.devices.forEach((deviceId) => {
        const device = store.getDeviceInSpaceById(group.spacePid, deviceId)
        removeDevice(device)
      })
      store.setSelectedFeature(null)
      logger.debug(`About to redraw map with features: ${store.features.map((f) => f.id)}`)
      redrawMap(store.features)
      logger.debug(`Finished redrawing map`, store)
    },
  })
}

function createDevice(device: Device) {
  try {
    addDeviceToMap(device, (feature) => {
      logger.debug(`adding device feature`, feature)
      store.addFeature(feature)
      store.addLinkedDevice(device)
      logger.debug(`About to redraw map with features: ${store.features.map((f) => f.id)}`)
      redrawMap(store.features)
    })
  } catch (e) {
    toast.add({
      life: 2500,
      severity: 'warn',
      summary: 'Cannot add device to floor plan',
      detail: 'Please check space is added first',
    })
  }
}

function removeDevice(device: Device) {
  store.removeFeatureForDevice(device)
  logger.debug(`About to redraw map with features: ${store.features.map((f) => f.id)}`)
  store.setSelectedFeature(null)
  redrawMap(store.features)
}
</script>

<template>
  <BasePage title="Editor" :location="store.location">
    <template #sidebar>
      <section class="sidebar-content">
        <FloorplanList
          :loading="isPageLoading"
          :floorplans="store.floorplans"
          :activeFloorplan="store.activeFloorplan"
          show-actions
          @floorplanSelected="goToFloorplan"
          show-create
          @create-clicked="showCreateFloorplanDialog = true"
          @delete-floorplan="
            (floorplan) => {
              floorplanActioned = floorplan
              showDeleteFloorplanDialog = true
            }
          "
          @edit-floorplan="
            (floorplan) => {
              floorplanActioned = floorplan
              showEditFloorplanDialog = true
            }
          "
        />
        <h2>Spaces</h2>
        <IconField iconPosition="left">
          <InputIcon class="pi pi-search" />
          <InputText class="search-input" placeholder="Search" @keyup="handleSearch"></InputText>
        </IconField>

        <div class="spaces-list">
          <template v-if="isPageLoading">
            <SpaceCardSkeleton />
            <SpaceCardSkeleton />
            <SpaceCardSkeleton />
          </template>
          <template v-else>
            <SpaceCard
              :id="`space-card-${space.id}`"
              @add-space="createSpace(space)"
              @remove-space="removeSpace(space)"
              @add-group="createGroup"
              @remove-group="removeGroup"
              @add-device="createDevice"
              @remove-device="removeDevice"
              v-if="store.spaces.length"
              v-for="space in store.spaces"
              :space="space"
              :isLoading="isFloorplanLoading"
            ></SpaceCard>
            <p v-else>No spaces found</p>
          </template>
        </div>
      </section>
      <section class="footer">
        <Button
          :disabled="isPageLoading || !store.hasChangesToSave || isSaving"
          type="button"
          loading-icon="pi pi-spinner pi-spin"
          class="save-button"
          @click="handleSave"
          :loading="isSaving"
          label="Save changes"
        />
      </section>
    </template>
    <template #main>
      <ConfirmDialog></ConfirmDialog>
      <CreateFloorplanDialog
        :visible="showCreateFloorplanDialog"
        @close="showCreateFloorplanDialog = false"
        @created="handleFloorplanCreation"
      />
      <EditFloorplanDialog
        :visible="showEditFloorplanDialog"
        :floorplan="floorplanActioned"
        @close="showEditFloorplanDialog = false"
        @edited="handleFloorplanEdition"
      />
      <DeleteFloorplanDialog
        :visible="showDeleteFloorplanDialog"
        :floorplan="floorplanActioned"
        @close="showDeleteFloorplanDialog = false"
        @deleted="handleFloorplanDeletion"
      />
      <div v-if="isPageLoading || isFloorplanLoading" class="main-loading">
        <i class="pi pi-spin pi-spinner"></i>
      </div>
      <div v-else-if="!store.floorplans.length" class="no-floorplans">
        No floor plans yet
        <Button
          @click="() => (showCreateFloorplanDialog = true)"
          label="Add a floor plan"
          icon="pi pi-plus-circle"
        ></Button>
      </div>
      <div
        v-show="(!isPageLoading || !isFloorplanLoading) && store.floorplans.length"
        ref="leafletElement"
        class="leaflet"
      ></div>
    </template>
  </BasePage>
</template>

<style scoped>
section {
  flex: 1;
  overflow-y: hidden;
}

section.sidebar-content {
  display: flex;
  flex-direction: column;
  gap: var(--section-padding);
}

.search-input {
  width: 100%;
}

section.sidebar-content .spaces-list {
  overflow-y: auto;
  overflow-x: visible;
  margin: 2px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

section.footer {
  flex: 0.1;
  max-height: 35px;
}

.save-button {
  width: 100%;
  display: flex;
  justify-content: center;
}
.leaflet {
  height: 100%;
  background-color: #fff;
}
.main-loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--p-surface-300);
}
.main-loading i {
  font-size: 32px;
}
.no-floorplans {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
}
.converting-floorplan-image {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
}
</style>
