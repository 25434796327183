import L, { Polyline, Map, Polygon } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'
import './editor.css'
import store from './store'
import { createFloorplanMap, deviceIcons, getDeviceMarker, groupStyles, spaceStyles } from '@/modules/leaflet'
import { getSpaceFeatureId } from '@/modules/spaces'
import { getGroupFeatureId } from '@/modules/groups'
import { getDeviceFeatureId } from '@/modules/devices'
import { Device, Group } from '@/entities/AvionLocation'

import { createLogger } from '@/utils/logger'
const logger = createLogger('floorplan-manager:editor')

type EditorLayer = Polyline & {
  onDeselect?: () => void
}

let map: Map = null
// Create separate layer groups for each type
let deviceLayer = L.layerGroup()
let spaceLayer = L.layerGroup()
let groupLayer = L.layerGroup()
// Track individual layers for selection management
let layerItems: EditorLayer[] = []

const customLabels = {
  tooltips: {
    placeMarker: 'Click to add device',
    firstVertex: 'Click to start drawing',
    continueLine: 'Draw the perimeter',
    finishPoly: 'Close the pemiterer to finalize ',
  },
}

export const removeMap = () => {
  if (map) {
    deviceLayer.clearLayers()
    spaceLayer.clearLayers()
    groupLayer.clearLayers()
    map.remove()
  }
}

export const finishEditing = () => {
  map?.pm.disableGlobalEditMode()
  map?.pm.disableGlobalDragMode()
}

let featureToAdd = null

const clearNonAddedFeatures = () => {
  if (featureToAdd) {
    // if there was a device to add but wasn't added, remove the once event
    map.off('pm:create')
  }
}

export const createFeatureForSpace = (feature, space) => {
  feature.id = getSpaceFeatureId(space)
  feature.properties = {
    spaceId: space.pid,
    type: 'space',
    label: `${space?.name ?? 'N/A'}`,
  }
}

export const addSpaceToMap = (space, onCreate) => {
  clearNonAddedFeatures()
  featureToAdd = space

  map.pm.enableDraw('Polygon', {
    snappable: true,
    snapDistance: 10,
  })

  // disable click to allow snapping to other spaces
  layerItems.forEach((layer) => {
    if (layer.feature?.properties.type === 'space') {
      layer.off('click')
    }
  })

  map.once('pm:create', ({ layer }: { layer: Polygon }) => {
    let feature = layer.toGeoJSON()
    createFeatureForSpace(feature, space)

    logger.debug(`Space created with feature`, feature)
    onCreate(feature)

    // Geoman clean up
    layer.remove()
    map.pm.disableDraw()
    featureToAdd = null
  })
}

export const createFeatureForGroup = (feature, group: Group) => {
  feature.id = getGroupFeatureId(group)
  feature.properties = {
    groupId: group.pid,
    type: 'group',
    label: `${group?.name ?? 'N/A'}`,
  }
}
export const addGroupToMap = (group: Group, onCreate) => {
  clearNonAddedFeatures()
  featureToAdd = group

  map.pm.enableDraw('Polygon', {
    snappable: true,
    snapDistance: 10,
  })

  // disable click to allow snapping to other spaces
  layerItems.forEach((layer) => {
    if (['space', 'group'].includes(layer.feature?.properties.type)) {
      layer.off('click')
    }
  })

  map.once('pm:create', ({ layer }: { layer: Polygon }) => {
    let feature = layer.toGeoJSON()
    createFeatureForGroup(feature, group)

    logger.debug(`Group created with feature`, feature)
    onCreate(feature)

    // Geoman clean up
    layer.remove()
    map.pm.disableDraw()
    featureToAdd = null
  })
}

export const createFeatureForDevice = (feature, device) => {
  feature.id = getDeviceFeatureId(device)
  feature.properties = {
    deviceId: device.pid,
    type: 'device',
    label: device.name,
  }
}

export const addDeviceToMap = (device: Device, onCreate) => {
  clearNonAddedFeatures()
  featureToAdd = device

  map.pm.enableDraw('Marker', {
    snappable: false,
    markerStyle: { icon: deviceIcons.unselected },
  })

  map.once('pm:create', ({ layer }: { layer: Polygon }) => {
    let feature = layer.toGeoJSON()
    createFeatureForDevice(feature, device)
    logger.debug(`Device created with feature`, feature)
    onCreate(feature)

    // Geoman clean up
    layer.remove()
    map.pm.disableDraw()
    featureToAdd = null
  })
}

const deselectAll = () => {
  layerItems.forEach((layer) => {
    layer?.onDeselect()
  })
  store.setSelectedFeature(null)
}

const onDeviceFeature = (feature, layer) => {
  logger.debug('Setting up device feature:', feature)
  layer.on('click', (param) => {
    L.DomEvent.stopPropagation(param)

    logger.debug('Device layer clicked:', param)

    // disable edit for all
    deselectAll()

    layer.setIcon(deviceIcons.selected)
    store.setSelectedFeature(feature, 'device')
  })

  layer.on('pm:dragend', ({ layer }) => {
    logger.debug('pm:dragend called for layer', layer)
    store.updateFeature(layer.toGeoJSON())
  })

  layer.onDeselect = () => {
    logger.debug('Deselecting device layer:', layer)
    layer.setIcon(deviceIcons.unselected)
  }
}

const onPolygonFeature = (feature, layer, styles) => {
  const updatePolygonLabel = (l) => {
    l.bindTooltip(feature.properties.label, {
      permanent: true,
      direction: 'center',
    })
  }

  logger.debug(`Setting up ${feature.properties.type} feature:`, feature)
  layer.on('click', (param) => {
    L.DomEvent.stopPropagation(param)

    logger.debug('Group layer clicked:', param)

    // disable edit for all
    deselectAll()

    layer.setStyle?.(styles.selected)
    store.setSelectedFeature(feature, feature.properties.type)
  })

  layer.on('pm:drag', ({ layer }) => {
    updatePolygonLabel(layer)
    layer.setStyle?.(styles.selected)
  })

  layer.on('pm:dragend', ({ layer }) => {
    logger.debug('pm:dragend called for layer', layer)
    store.updateFeature(layer.toGeoJSON())
  })

  layer.on('pm:edit', () => {
    logger.debug('pm:edit called for layer', layer)
    updatePolygonLabel(layer)
    store.updateFeature(layer.toGeoJSON())
  })

  layer.onDeselect = () => {
    logger.debug('Deselecting group layer:', layer)
    layer.setStyle?.(styles.normal)
  }

  updatePolygonLabel(layer)
  layer.setStyle?.(styles.normal)
}

export function redrawMap(features) {
  logger.debug('Redrawing map...')
  // Clear all layers
  deviceLayer.clearLayers()
  spaceLayer.clearLayers()
  groupLayer.clearLayers()
  layerItems = []

  // Add new features to the map
  features.forEach((feature) => {
    const geoJSONLayer = L.geoJSON([feature], {
      pane: `${feature.properties.type}Pane`,
      snapIgnore: feature.properties.type === 'device',
      onEachFeature: (feature, layer) => {
        logger.debug('Processing feature:', feature)

        switch (feature.properties.type) {
          case 'device':
            onDeviceFeature(feature, layer)
            break
          case 'space':
            onPolygonFeature(feature, layer, spaceStyles)
            break
          case 'group':
            onPolygonFeature(feature, layer, groupStyles)
            break
        }

        logger.debug('Adding layer to global layers array:', layer)
        layerItems.push(layer as Polyline)
      },
      pointToLayer: (feature, coordinates) => {
        return getDeviceMarker(feature, coordinates)
      },
    })

    // Add to appropriate layer group based on feature type
    switch (feature.properties.type) {
      case 'device':
        geoJSONLayer.addTo(deviceLayer)
        break
      case 'space':
        geoJSONLayer.addTo(spaceLayer)
        break
      case 'group':
        geoJSONLayer.addTo(groupLayer)
        break
    }
  })

  map.pm.setGlobalOptions({
    // disable snapping by default
    snappable: false,
  })
  map.pm.applyGlobalOptions()
}

export function createMap(leafletElement: any) {
  map = createFloorplanMap(leafletElement, store.activeFloorplan)

  map.pm.addControls({
    position: 'topright',
    drawMarker: false,
    drawCircleMarker: false,
    drawPolyline: false,
    drawRectangle: false,
    drawPolygon: false,
    drawCircle: false,
    drawText: false,
    cutPolygon: false,
    removalMode: false,
    rotateMode: false,
  })

  map.pm.setLang('en', customLabels, 'en')

  map.on('click', () => {
    deselectAll()
  })

  // Add all layer groups to the map
  deviceLayer.addTo(map)
  spaceLayer.addTo(map)
  groupLayer.addTo(map)

  // Create layer control
  const overlays = {
    Devices: deviceLayer,
    Groups: groupLayer,
    Spaces: spaceLayer,
  }

  L.control
    .layers(null, overlays, {
      collapsed: true,
    })
    .addTo(map)

  // Groups in a higher zIndex to avoid being covered by a Space
  map.createPane('spacePane').style.zIndex = '400'
  map.createPane('groupPane').style.zIndex = '401'
}
