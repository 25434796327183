import { reactive } from 'vue'

const state = reactive<{
  version: string
  rollbarEnabled: boolean
  rollbarApiKey: string
  appEnvironment: string
}>({
  version: '',
  rollbarEnabled: false,
  rollbarApiKey: '',
  appEnvironment: '',
})

const store = {
  get config() {
    return state
  },
  setConfig(config: any) {
    state.version = config.version
    state.rollbarEnabled = config.rollbarEnabled
    state.rollbarApiKey = config.rollbarApiKey
    state.appEnvironment = config.appEnvironment
  },
}

export default store
