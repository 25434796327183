<script lang="ts" setup>
import { ref, defineProps, defineEmits, nextTick } from 'vue'
import { LayerControlSpace, LayerControlDevice } from '@/pages/LayerDeviceControl/store'
import SearchInput from '@/components/SearchInput.vue'
import DeviceDetailsCard from '@/pages/LayerDeviceControl/components/DeviceDetailsCard/index.vue'
import GroupControl from '@/pages/LayerDeviceControl/components/GroupControl/index.vue'
import { fuzzyFilter } from '@/modules/filter'
import store from '@/pages/LayerDeviceControl/store'
import { showDeviceSelected } from './helpers.ts'
import { selectDevice } from '@/pages/LayerDeviceControl/editor'
import { Group } from '@/entities/AvionLocation'

defineOptions({
  name: 'SpaceDetails',
})

const props = defineProps<{
  space: LayerControlSpace
  group: Group
  isLoading?: boolean
}>()

const searchValue = ref('')
const emit = defineEmits(['dimChanged', 'onOffChanged', 'onGroupStateChanged'])

const filteredDevices = (): LayerControlDevice[] => {
  const selectedGroup = store.getSelectedGroup()

  const devices = searchValue.value
    ? fuzzyFilter({
        items: props.space.devices,
        key: 'name',
        query: searchValue.value,
      })
    : props.space.devices

  const linkedDevices = devices.filter((device) => store.isDeviceLinked(device))
  return selectedGroup ? linkedDevices.filter((device) => selectedGroup?.devices.includes(device.pid)) : linkedDevices
}

const handleSelectDevice = (pid: string) => {
  selectDevice(pid)
}

const handleCollapsed = (device: LayerControlDevice) => {
  const isCollapsed = !showDeviceSelected(device, store)
  if (!isCollapsed) {
    nextTick(() => {
      const element = document.getElementById(`device-card-${device.pid}`)
      element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
    })
  }
  return isCollapsed
}

const handleDimChanged = (value: any) => {
  emit('dimChanged', value)
}

const handleOnOffChange = (value: any) => {
  emit('onOffChanged', value)
}

const hadleGroupStateChanged = (value: any) => {
  emit('onGroupStateChanged', value)
}
</script>

<template>
  <section class="container">
    <header>
      <h3>
        <span>{{ group?.name || 'All groups' }}</span>
      </h3>
    </header>
    <GroupControl
      v-if="store.getSelectedGroup()?.name"
      :group="store.getSelectedGroup()"
      @on-group-state-changed="hadleGroupStateChanged"
      :isLoading="isLoading"
    />
    <section class="devices" v-if="filteredDevices().length">
      <hr />
      <SearchInput v-model="searchValue" />
      <section class="devices-list">
        <DeviceDetailsCard
          :id="`device-card-${device.pid}`"
          v-for="device in filteredDevices()"
          :header="device.name"
          toggleable
          :device="device"
          :collapsed="handleCollapsed(device)"
          :class="['device-card', { selected: showDeviceSelected(device, store) }]"
          :key="device.pid"
          @click.stop="handleSelectDevice(device.pid)"
          @dim-changed="handleDimChanged"
          @on-off-changed="handleOnOffChange"
        />
      </section>
    </section>
  </section>
</template>

<style scoped>
hr {
  border: 1px solid #e0e0e0;
  width: 100%;
}
section.container {
  height: 100%;
  overflow-y: auto;
  padding: var(--section-padding);
  background-color: var(--p-surface-50);
  max-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
header h3 span {
  color: var(--p-surface-600);
}
.devices {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.devices-list {
  flex-grow: 1;
  overflow-y: auto;
}
.device-card {
  margin-bottom: 8px;
  box-shadow: var(--card-shadow);
  cursor: pointer;
}
.selected {
  background-color: var(--p-indigo-50);
  border: 1px solid var(--p-indigo-400);
}
</style>
