import { reactive } from 'vue'

type CookieUser = {
  firstName: string
  lastName: string
  email: string
  pid: number
  authToken: string
  refreshToken: string
  emailVerified: boolean
  phoneVerified: boolean
}

const state = reactive<{ firstName: string; lastName: string; authToken: string }>({
  firstName: '',
  lastName: '',
  authToken: '',
})

const store = {
  get user() {
    return state
  },
  setUser(user: CookieUser) {
    state.firstName = user.firstName
    state.lastName = user.lastName
    state.authToken = user.authToken
  },
}

export default store
