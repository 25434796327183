import { createApp } from 'vue'
import userStore from '@/stores/user.ts'
import configStore from '@/stores/config.ts'
import http from '@/modules/http.ts'
import { getEnvironmentFromUrl, extractCredentials, getLoginUrl } from '@/utils/auth'
import { setupRollbar } from '@/utils/rollbar'
import { createRouter, createWebHistory } from 'vue-router'
import { definePreset } from '@primevue/themes'
import PrimeVue from 'primevue/config'
import Aura from '@primevue/themes/aura'
import ToastService from 'primevue/toastservice'
import ConfirmationService from 'primevue/confirmationservice'
import { routes } from './routes'
import './style.css'
import App from './App.vue'
import Tooltip from 'primevue/tooltip';


const router = createRouter({
  history: createWebHistory(),
  routes,
})

const app = createApp(App)
app.directive('tooltip', Tooltip);


// ** Primevue configuration **
const MyPreset = definePreset(Aura, {
  semantic: {
    primary: {
      50: '{blue.50}',
      100: '{blue.100}',
      200: '{blue.200}',
      300: '{blue.300}',
      400: '{blue.400}',
      500: '{blue.500}',
      600: '{blue.600}',
      700: '{blue.700}',
      800: '{blue.800}',
      900: '{blue.900}',
      950: '{blue.950}',
    },
  },
})

import 'primeicons/primeicons.css'

app.use(PrimeVue, {
  theme: {
    preset: MyPreset,
    options: {
      darkModeSelector: 'off',
    },
  },
})
app.use(router)
app.use(ToastService)
app.use(ConfirmationService)

// ** Authentication check **
const credentials = extractCredentials(document.cookie, getEnvironmentFromUrl(window.location.href))

if (!credentials) {
  const loginUrl = getLoginUrl(window.location.href)
  // redirect to login by assigning new location.href
  loginUrl && (window.location.href = loginUrl)
} else if (window.location.pathname === '/') {
  // redirect to location manager
  const page = credentials?.pages?.find(page => page.includes('location'))
   page ? (window.location.href = `https://${page}`) : (window.location.href = 'https://location.avi-on.com')
} else {

  userStore.setUser(credentials)
  // ** Fetch app configuration from server and initialize Vue application **
  http.get('/config')
    .then((appConfig) => {
      configStore.setConfig(appConfig)
      // Setup error logger
      setupRollbar()
      app.mount('#app')
    })
    .catch((error) => {
      console.error('Could not load config:', error)
    })
}
