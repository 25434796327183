<script lang="ts" setup>
import Toast from 'primevue/toast'
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'
import store from './stores/location'
import http from '@/modules/http.ts'
import { AvionLocation } from './entities/AvionLocation'
import { useToast } from 'primevue/usetoast'

const toast = useToast()

const route = useRoute()

onMounted(async () => {
  try {
    const location = await http.get<AvionLocation>(`/locations/${route.params.locationId}`)
    location && store.setLocation(location)
    if (location?.name) {
      document.title = `Floorplan view - ${location.name}`
    }
  } catch (error) {
    console.error(error)
    const errorMessage =
      error.response?.status === 500
        ? 'Internal server error. Please try again later.'
        : error.message || 'An unexpected error occurred.'

    toast.add({
      summary: 'Could not load location',
      detail: errorMessage,
      life: 1300,
      severity: 'error',
    })
  }
})
</script>

<template>
  <Toast position="bottom-center" />
  <router-view />
</template>

<style></style>
