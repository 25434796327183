import { AvionLocation } from '@/entities/AvionLocation'
import { reactive } from 'vue'

const state = reactive<{
  location: AvionLocation | null
}>({
  location: null,
})

const store = {
  get location() {
    return state.location
  },
  setLocation(location: AvionLocation) {
    state.location = location
  },
}

export default store
