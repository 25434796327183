import configStore from '@/stores/config.ts'

const createLogger = (prefix: string, showLogs = true) => {
  const log = (level, ...args) => {
    if (showLogs) {
      const logFn = console[level] ?? console.log
      const date = new Date()
      const timestamp = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`

      logFn(`[${timestamp}] ${level.toUpperCase()} (${prefix})`, ...args)
    }
  }
  return {
    info: (...args) => {
      log('info', ...args)
    },
    debug: (...args) => {
      log('debug', ...args)
    },
    trace: (...args) => {
      log('trace', ...args)
    },
    error: (...args) => {
      log('error', ...args)
      try {
        if (configStore.config.rollbarEnabled) {
          // @ts-ignore
          window.Rollbar?.error(...args)
        }
      } catch (error) {
        log('Error while sending log to Rollbar:', error)
      }
    },
    warn: (...args) => {
      log('warn', ...args)
    },
  }
}

export { createLogger }
