<script setup lang="ts">
import { defineProps, PropType, useAttrs } from 'vue'

defineProps({
  icon: {
    type: String as PropType<string>,
    required: true,
  },
  label: {
    type: String as PropType<string>,
    required: true,
  },
  active: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const attrs = useAttrs()
</script>

<template>
  <li role="menuitem" :class="{ active: active }" v-bind="attrs">
    <i class="pi" :class="`pi-${icon}`"></i> <span>{{ label }}</span>
    <slot></slot>
  </li>
</template>

<style scoped>
li[role='menuitem'] {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  flex-direction: column;
  height: 50px;
  color: #f3fbfd;
  transition: background-color 0.3s;
  padding: 8px 0;
  border-radius: 4px;
  font-size: 12px;
  margin: 0 6px 6px 6px;
  position: relative;
}

li[role='menuitem']:not(.active) i {
  color: #27bec4;
}

li[role='menuitem'].active {
  background-color: #2d4263;
}

nav ul li[role='menuitem']:not(.active):hover {
  background-color: #2d4263;
}

li[role='menuitem'].expanded .nested-container {
  display: inherit;
}
</style>
